import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { IRmaShippingMethod } from 'graphql/rma/rmaInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { RadioButton } from 'ui/component/custom/radio-button';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import { IMethodsState, IMethodsStatus } from '../rma-shipping-methods';
import styles from './style.css';


interface IRmaShippingMethodProps {
    config: ISalesLayoutConfig;
    method: IRmaShippingMethod;
    methodsState: IMethodsState;
    methodsStatus: IMethodsStatus;
    onChangeMethod: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
}

export const RmaShippingMethod: React.FC<IRmaShippingMethodProps> = (
    {
        method,
        methodsState,
        methodsStatus,
        onChangeMethod,
        className,
    },
) => {
    const t = usePhraseTranslater();

    const {
        available,
        code,
        title,
        price,
        desc,
    } = method;
    const isFree = !(price > 0);

    const methodPrice: JSX.Element = (
        <div className={classes(styles.methodPrice, { [styles.free]: isFree })}>
            {isFree && (t('FREE'))}
            {!isFree && (<SummaryLine amount={price} />)}
        </div>
    );

    if (!available) {
        return null;
    }

    return (
        <div className={classes(styles.rmaShippingMethodWrapper, (className || ''))}>
            <RadioButton
                id={`rma-shipping-method-id-${code}`}
                name={`rma_shipping_method_name_${code}`}
                value={code}
                label={title}
                contentAfterLabel={methodPrice}
                wrapperClassName={styles.refundOptionRadioBtn}
                afterLabelClassName={styles.methodPriceWrapper}
                isChecked={methodsState[code]}
                onChange={onChangeMethod}
                status={methodsStatus[code]}
            />
            <div className={styles.rmaShippingMethodDetails}>
                <div className={styles.rmaShippingMethodDescription}>{desc}</div>
            </div>
        </div>
    );
};
