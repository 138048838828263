import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
    IChosenReturnItem,
    rmaReasonOptionsVar,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { IRmaOption } from 'graphql/rma/rmaInterfaces';
import { getOptionLabel } from '../../../../../../util/rma-select-options';
import styles from '../style.css';

export interface IReasonDetailsProps {
    chosenReturnItem: IChosenReturnItem;
}

export const ReasonDetails: React.FC<IReasonDetailsProps> = ({ chosenReturnItem }) => {
    const rmaReasonOptions: IRmaOption[] = useReactiveVar(rmaReasonOptionsVar);

    const { reason } = chosenReturnItem;

    if (reason === '') {
        return null;
    }

    const reasonLabel = getOptionLabel(rmaReasonOptions, reason);

    return (
        <span className={styles.reason}>{reasonLabel}</span>
    );
};
