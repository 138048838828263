import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { LinkPopup } from 'ui/component/custom/link-popup';
import {
    STEP_RETURN_REASONS,
    STEP_REFUND_OPTIONS,
    STEP_SHIPPING_OPTIONS,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { DetailsContent } from './details-content';
import styles from './style.css';

export interface ISeeDetailsProps {
    stepKey: string;
}

export const SeeDetails: React.FC<ISeeDetailsProps> = (
    {
        stepKey,
    },
) => {
    const t = usePhraseTranslater();

    const getDetailsContent = (): JSX.Element|null => {
        switch (stepKey) {
            case STEP_RETURN_REASONS:
                return (<DetailsContent stepKey={stepKey} />);
            case STEP_REFUND_OPTIONS:
                return (<DetailsContent stepKey={stepKey} />);
            case STEP_SHIPPING_OPTIONS:
            default:
                return null;
        }
    };

    const stepDetailsContent = getDetailsContent();
    if (!stepDetailsContent) {
        return null;
    }

    return (
        <LinkPopup
            linkLabel={t('See Details')}
            wrapperClassName={styles.seeDetailsWrapper}
            linkClassName={styles.seeDetailsLink}
            contentClassName={styles.seeDetailsContentWrapper}
        >
            {stepDetailsContent}
        </LinkPopup>
    );
};
