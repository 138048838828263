import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { Link } from 'ui/component/custom/link';
import styles from './style.css';

interface IRmaRequestFormProps {
    rmaFormUrl: string;
    rmaFormLabel?: string;
    rmaFormLinkLabel?: string;
    className?: string;
}

export const RmaRequestForm: React.FC<IRmaRequestFormProps> = (
    {
        rmaFormUrl,
        rmaFormLabel,
        rmaFormLinkLabel,
        className,
    },
) => {
    const t = usePhraseTranslater();

    if (!rmaFormUrl.length) {
        return null;
    }

    return (
        <div className={classes(styles.rmaFormInfo, (className || ''))}>
            {rmaFormLabel && rmaFormLabel.length > 0 && (
                <div className={styles.returnFormLabel}>
                    {rmaFormLabel}
                </div>
            )}
            <Link
                href={rmaFormUrl}
                label={rmaFormLinkLabel || t('RMA Request Form')}
                linkClassName={styles.rmaFormUrl}
                target="_blank"
                opacityOnHover
                underlineOnHover
            />
        </div>
    );
};
