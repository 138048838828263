import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IOrderItem, TypeSplitOrderItem } from 'graphql/customer/customerOrdersInterfaces';
import {
    IChosenReturnItem,
    returnReasonsDataVar,
    STEP_RETURN_REASONS,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { RmaItem } from './rma-item';
import { IStepProps } from '../steps';
import stepsStyles from '../style.css';
import styles from './style.css';

export const StepReturnReasons: React.FC<IStepProps> = ({ config, rmaItems, currentStepKey }) => {
    const t = usePhraseTranslater();

    const chosenReturnItems: IChosenReturnItem[] = useReactiveVar(returnReasonsDataVar);

    const { length } = rmaItems;

    if (currentStepKey !== STEP_RETURN_REASONS) {
        return null;
    }

    return (
        <div className={classes(stepsStyles.step, styles.rmaItems)} data-test={`step-${currentStepKey}`}>
            {rmaItems.map(
                (rmaItem: IOrderItem|TypeSplitOrderItem) => (
                    <RmaItem
                        config={config}
                        rmaItem={rmaItem}
                        chosenReturnItems={chosenReturnItems}
                    />
                ),
            )}
            {length > 0 && (
                <div className={classes(styles.rmaItemsInfo, styles.returnableItems)}>
                    {t('Showing %1 returnable items', length)}
                </div>
            )}
            {length <= 0 && (
                <div className={classes(styles.rmaItemsInfo, styles.noRmaItems)}>
                    {t('There are no available returnable items')}
                </div>
            )}
        </div>
    );
};
