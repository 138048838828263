import React, { useState } from 'react';
import { useReactiveVar, useMutation, ApolloError } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import { ActionButton } from 'ui/component/custom/action-button';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import {
    IRequestReturnInputItem,
    IRequestReturn,
} from 'graphql/rma/rmaInterfaces';
import {
    IRequestReturnInput,
    IRequestReturnOutput,
    IGuestRequestReturnOutput,
} from 'graphql/rma/mutations/returnMutationsInterfaces';
import {
    CUSTOMER_REQUEST_RETURN,
    GUEST_REQUEST_RETURN,
} from 'graphql/rma/mutations/requestReturn';
import {
    IChosenReturnItem,
    IChosenRefundOption,
    IChosenShippingOption,
    resetStoredData,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import {
    ISalesLayoutConfig,
    isLoggedInVar,
} from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { getRefundOptionsItemData } from '../../../util/steps-data-processor';
import styles from '../style.css';

interface ICreateReturnActionProps {
    config: ISalesLayoutConfig;
    createReturnsOrder: ICustomerOrder|undefined;
    chosenReturnItems: IChosenReturnItem[];
    chosenRefundOptions: IChosenRefundOption[];
    chosenShippingOption: IChosenShippingOption|undefined;
    setError: (isError: boolean) => void;
    setSuccess: (isSuccess: boolean) => void;
}

export const CreateReturnAction: React.FC<ICreateReturnActionProps> = (
    {
        config,
        createReturnsOrder,
        chosenReturnItems,
        chosenRefundOptions,
        chosenShippingOption,
        setError,
        setSuccess,
    },
) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();
    const isLoggedIn = useReactiveVar(isLoggedInVar);

    const orderUid = createReturnsOrder?.id || '';
    const { salesConfig } = config;
    const { rmaConfig } = salesConfig;
    const { returnsUrl } = rmaConfig;

    const getReturnItems = (): IRequestReturnInputItem[] => chosenReturnItems.map((chosenReturnItem) => {
        const {
            item,
            requestedQty,
            reason,
            condition,
            comment,
            uploadedImages,
        } = chosenReturnItem;
        const { id } = item;
        const chosenRefundOption = getRefundOptionsItemData(chosenRefundOptions, id);
        const resolution = chosenRefundOption?.chosenOption ? chosenRefundOption.chosenOption?.rmaOption?.value : '';
        return {
            order_item_uid: id,
            quantity_to_return: `${requestedQty}`,
            entered_custom_attributes: [
                {
                    attribute_code: 'comment_text',
                    value: comment,
                },
                {
                    attribute_code: 'uploaded_images',
                    value: JSON.stringify(uploadedImages),
                },
            ],
            selected_custom_attributes: [
                {
                    attribute_code: 'reason',
                    value: reason,
                },
                {
                    attribute_code: 'condition',
                    value: condition,
                },
                {
                    attribute_code: 'resolution',
                    value: resolution,
                },
            ],
        };
    });

    const getCombinedCommentText = (): string => {
        const comments: string[] = [];
        chosenReturnItems.forEach((chosenReturnItem) => {
            const { item, comment } = chosenReturnItem;
            if (comment && comment.length > 0) {
                const { product_sku: sku } = item;
                const newComment = t('comment for SKU %1 - "%2"', sku, comment);
                comments.push(newComment);
            }
        });
        if (!comments.length) {
            return '';
        }

        const commentsText = comments.join('; ');
        return t('Comments: %1', commentsText);
    };

    const getShippingMethod = (): string => (
        JSON.stringify(chosenShippingOption?.chosenShippingMethod || '')
    );

    const getVariables = (): IRequestReturnInput => ({
        input: {
            order_uid: orderUid,
            items: getReturnItems(),
            comment_text: getCombinedCommentText(),
            shipping_method: getShippingMethod(),
        },
    });

    const resolveReturnResponse = (requestReturn: IRequestReturn): void => {
        const { return: returnResponse } = requestReturn;
        const { items } = returnResponse;
        if (items.length === chosenReturnItems.length) {
            setSuccess(true);
            resetStoredData();
            setTimeout(() => {
                navigateToUrl({
                    navigate,
                    url: returnsUrl,
                });
            }, 5000);
        }
    };

    const onCustomerCompleted = async (data: IRequestReturnOutput): Promise<void> => {
        const { requestReturn } = data;
        resolveReturnResponse(requestReturn);
    };

    const onGuestCompleted = async (data: IGuestRequestReturnOutput): Promise<void> => {
        const { guestRequestReturn } = data;
        resolveReturnResponse(guestRequestReturn);
    };

    const onError = (_: ApolloError) => {
        setError(true);
        resetStoredData();
    };

    const getReturnRequestData = () => {
        const variables: IRequestReturnInput = getVariables();
        return {
            variables,
            onError,
        };
    };

    const [customerRequestReturn] = useMutation(CUSTOMER_REQUEST_RETURN);
    const [guestRequestReturn] = useMutation(GUEST_REQUEST_RETURN);

    const createReturnAction = async () => {
        const requestReturnData = getReturnRequestData();
        if (isLoggedIn) {
            await customerRequestReturn({
                ...requestReturnData,
                onCompleted: onCustomerCompleted,
            });
        } else {
            await guestRequestReturn({
                ...requestReturnData,
                onCompleted: onGuestCompleted,
            });
        }
    };

    const onCreateReturns = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        setError(false);
        setSuccess(false);
        setIsDisabled(true);
        createReturnAction().finally(() => {
            // TODO: come up with the better disabling logic `setIsDisabled(false)`
        });
    };

    return (
        <ActionButton
            label={t('Create Returns')}
            btnClassName={styles.sidebarActionBtn}
            disabled={isDisabled}
            action={onCreateReturns}
        />
    );
};
