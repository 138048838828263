import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { IOrderItem } from 'graphql/customer/customerOrdersInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    IStepsValidation,
    STEP_RETURN_REASONS,
    STEP_REFUND_OPTIONS,
    STEP_SHIPPING_OPTIONS,
    createReturnsSteps,
    currentStepVar,
    stepsValidationVar,
    stepsVisibilityVar,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { StepHeader } from './step-header';
import { StepReturnReasons } from './step-return-reasons';
import { StepRefundOptions } from './step-refund-options';
import { StepShippingOptions } from './step-shipping-options';
import {
    updateStepsVisibility,
    getCurrentStepFromUrl,
    navigateToStep,
} from '../../util/rma-steps-resolver';
import styles from './style.css';

interface IStepsProps {
    config: ISalesLayoutConfig;
    rmaItems: IOrderItem[];
}

export interface IStepProps {
    config: ISalesLayoutConfig;
    rmaItems: IOrderItem[];
    currentStepKey: string;
    currentStepTitle: string;
}

interface IStepTitle {
    [key: string]: string;
}

const stepComponents: {[key: string]: React.ElementType} = {
    [STEP_RETURN_REASONS]: StepReturnReasons,
    [STEP_REFUND_OPTIONS]: StepRefundOptions,
    [STEP_SHIPPING_OPTIONS]: StepShippingOptions,
};

export const Steps: React.FC<IStepsProps> = ({ config, rmaItems }) => {
    const currentStepKey: string = useReactiveVar(currentStepVar);
    const stepsValidation: IStepsValidation = useReactiveVar(stepsValidationVar);
    const stepsVisibility: IStepsValidation = useReactiveVar(stepsVisibilityVar);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const stepTitles: IStepTitle = {
        [STEP_RETURN_REASONS]: t('Why are you returning this?'),
        [STEP_REFUND_OPTIONS]: t('How can we make it right?'),
        [STEP_SHIPPING_OPTIONS]: t('How would you like to return your items?'),
    };

    const getStepTitle = (stepKey: string) => stepTitles[stepKey];
    const getStepComponentByKey = (stepKey: string): React.ElementType => stepComponents[stepKey];

    const onClickStepHeader = (stepKey: string): void => {
        if (currentStepKey !== stepKey) {
            navigateToStep(navigate, stepKey);
        }
    };

    useEffect(() => {
        const currentStep: string = getCurrentStepFromUrl(true);
        if (currentStepKey === '') {
            navigateToStep(navigate, currentStep, true);
        }
    }, []);

    useEffect((): void => {
        const currentStep: string = getCurrentStepFromUrl(false);
        if (currentStepKey !== currentStep) {
            currentStepVar(currentStep);
        }
    }, [window.location.search]);

    useEffect((): void => {
        updateStepsVisibility(currentStepKey);
    }, [stepsValidation]);

    return (
        <div className={classes(styles.stepsWrapper)}>
            {createReturnsSteps.map(
                (stepKey: string) => (
                    <>
                        {stepsVisibility[stepKey] && (
                            <div
                                className={classes(styles.stepWrapper, {
                                    [styles.stepRefundOptions]: stepKey === STEP_REFUND_OPTIONS,
                                })}
                            >
                                <StepHeader
                                    stepKey={stepKey}
                                    stepTitle={getStepTitle(stepKey)}
                                    isActive={stepKey === currentStepKey}
                                    onClickStepHeader={onClickStepHeader}
                                />
                                {(
                                    React.createElement(getStepComponentByKey(stepKey), {
                                        config,
                                        rmaItems,
                                        currentStepKey,
                                        currentStepTitle: getStepTitle(stepKey),
                                    } as IStepProps)
                                )}
                            </div>
                        )}
                    </>
                ),
            )}
        </div>
    );
};
