import { IRmaRefundOption } from 'graphql/rma/rmaInterfaces';
import {
    IChosenReturnItem,
    IChosenRefundOption,
    IChosenShippingOption,
    IAgreementConfirmation,
    validateOnContinueVar,
    stepsValidationVar,
    allStepsValidVar,
    returnReasonsDataVar,
    createReturnsSteps,
    STEP_RETURN_REASONS,
    STEP_REFUND_OPTIONS,
    STEP_SHIPPING_OPTIONS,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { updateStepValidation } from './rma-steps-resolver';
import { findChosenItemById } from './steps-data-processor';

type IStepData = IChosenReturnItem[] | IChosenRefundOption[] | IChosenShippingOption | undefined;

export interface IStepValidator {
    validate: () => boolean;
}

export const isRequestedQtyValid = (chosenReturnItem: IChosenReturnItem): boolean => {
    const { item, requestedQty, remainingQty } = chosenReturnItem;
    const { rma_available_qty: availableQty } = item;

    const isChosenQtyValid = requestedQty > 0;
    const areQuantitiesValid = ((requestedQty + remainingQty) === availableQty);
    return isChosenQtyValid && areQuantitiesValid;
};

export const isReasonValid = (chosenReturnItem: IChosenReturnItem): boolean => {
    const { reason } = chosenReturnItem;
    return reason !== '';
};

export const isConditionValid = (chosenReturnItem: IChosenReturnItem): boolean => {
    const { condition } = chosenReturnItem;
    return condition !== '';
};

export const isCommentValid = (chosenReturnItem: IChosenReturnItem): boolean => {
    const { comment, rmaCommentRequired } = chosenReturnItem;
    return rmaCommentRequired
        ? Boolean(comment && comment.length > 0 && comment.length <= 200)
        : true;
};

export const isChosenItemValid = (chosenReturnItem: IChosenReturnItem): boolean => Boolean(
    isRequestedQtyValid(chosenReturnItem) &&
    isReasonValid(chosenReturnItem) &&
    isConditionValid(chosenReturnItem) &&
    isCommentValid(chosenReturnItem),
);

export const isRefundOptionValid = (refundOption?: IRmaRefundOption): boolean => {
    if (!refundOption) {
        return false;
    }

    const { rmaOption } = refundOption;
    return rmaOption && rmaOption.value !== '';
};

export const isAgreementsValid = (chosenRefundOption: IChosenRefundOption): boolean => {
    const {
        chosenOption,
        agreements: confirmedAgreements,
    } = chosenRefundOption;

    if (!chosenOption?.agreements) {
        return true;
    }

    const hasAgreed = confirmedAgreements && confirmedAgreements.length > 0;
    if (!hasAgreed) {
        return false;
    }

    const agreed: IAgreementConfirmation[] = confirmedAgreements.filter(agreement => agreement.isAgreed);
    return agreed.length === chosenOption.agreements.length;
};

export const isChosenRefundOptionValid = (chosenRefundOption: IChosenRefundOption): boolean => {
    const chosenReturnItems: IChosenReturnItem[] = returnReasonsDataVar();

    const foundChosenItem = findChosenItemById(chosenReturnItems, chosenRefundOption.chosenItemId);
    if (!foundChosenItem) {
        return false;
    }

    return (
        isChosenItemValid(foundChosenItem) &&
        isRefundOptionValid(chosenRefundOption.chosenOption) &&
        isAgreementsValid(chosenRefundOption)
    );
};

export const isChosenShippingOptionValid = (
    chosenShippingOption: IChosenShippingOption|undefined,
): boolean => Boolean(
    chosenShippingOption &&
    chosenShippingOption.chosenShippingMethod &&
    chosenShippingOption.chosenShippingMethod.code,
);

abstract class AbstractStepValidator implements IStepValidator {
    /**
     * @param stepKey
     * @param stepData
     * @param stepsList
     */
    constructor(
        protected stepKey: string = '',
        protected stepData?: IStepData|undefined,
        private stepsList: string[] = createReturnsSteps,
    ) {
    }

    abstract getStepValidationResult(): boolean;

    validate(): boolean {
        const validateOnContinue: boolean = validateOnContinueVar();
        const validateResult = this.getStepValidationResult();
        updateStepValidation(this.stepKey, validateResult);
        if (validateOnContinue) {
            validateOnContinueVar(!validateResult);
        }

        this.checkAreAllStepsValid();

        return validateResult;
    }

    private checkAreAllStepsValid(): void {
        const { stepsList } = this;
        const { length: stepsCount } = stepsList;

        const stepsValidation = stepsValidationVar();

        const validSteps: string[] = stepsList.filter((stepKey: string) => stepsValidation[stepKey]);
        const { length: validStepsCount } = validSteps;

        const areAllStepsValid = stepsCount === validStepsCount;

        allStepsValidVar(areAllStepsValid);
    }
}

class ReturnReasonsStepValidator extends AbstractStepValidator {
    getStepValidationResult(): boolean {
        if (this.stepKey !== STEP_RETURN_REASONS || !this.stepData) {
            return false;
        }

        const chosenReturnItems: IChosenReturnItem[] = this.stepData as IChosenReturnItem[];
        const validChosenItems: IChosenReturnItem[] = chosenReturnItems.filter(
            (chosenReturnItem: IChosenReturnItem) => isChosenItemValid(chosenReturnItem),
        );

        const hasValidChosenItems = validChosenItems.length > 0;
        const areAllChosenItemsValid = chosenReturnItems.length === validChosenItems.length;

        return Boolean(hasValidChosenItems && areAllChosenItemsValid);
    }
}

class RefundOptionsStepValidator extends AbstractStepValidator {
    getStepValidationResult(): boolean {
        if (this.stepKey !== STEP_REFUND_OPTIONS || !this.stepData) {
            return false;
        }

        const chosenOptions: IChosenRefundOption[] = this.stepData as IChosenRefundOption[];
        const validChosenOptions: IChosenRefundOption[] = chosenOptions.filter(
            (chosenRefundOption: IChosenRefundOption) => isChosenRefundOptionValid(chosenRefundOption),
        );

        const hasValidChosenOptions = validChosenOptions.length > 0;
        const areAllChosenOptionsValid = chosenOptions.length === validChosenOptions.length;

        return Boolean(hasValidChosenOptions && areAllChosenOptionsValid);
    }
}

class ShippingOptionsStepValidator extends AbstractStepValidator {
    getStepValidationResult(): boolean {
        if (this.stepKey !== STEP_SHIPPING_OPTIONS || !this.stepData) {
            return false;
        }

        const chosenShippingOption: IChosenShippingOption = this.stepData as IChosenShippingOption;
        return isChosenShippingOptionValid(chosenShippingOption);
    }
}

export const getStepValidator = (stepKey: string, stepData?: IStepData): IStepValidator => {
    switch (stepKey) {
        case STEP_RETURN_REASONS:
            return new ReturnReasonsStepValidator(stepKey, stepData);
        case STEP_REFUND_OPTIONS:
            return new RefundOptionsStepValidator(stepKey, stepData);
        case STEP_SHIPPING_OPTIONS:
            return new ShippingOptionsStepValidator(stepKey, stepData);
        default:
            return { validate: () => false };
    }
};
