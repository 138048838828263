import React from 'react';
import { IUploadImagesResponse } from '@silkpwa/magento/api/files-uploader/files-uploader';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import closeImage from 'assets/images/close.svg';
import styles from './style.css';

interface IUploadResultProps {
    uploadedImage: IUploadImagesResponse;
    removeImageAction?: (imageToRemove: IUploadImagesResponse) => void;
}

export const Image: React.FC<IUploadResultProps> = ({ uploadedImage, removeImageAction }) => {
    const { name, url } = uploadedImage;

    const onClickRemoveImage = (event?: React.KeyboardEvent | React.MouseEvent<Element, MouseEvent>) => {
        if (event) event.preventDefault();
        if (removeImageAction) removeImageAction(uploadedImage);
    };

    if (uploadedImage.error > 0) {
        return null;
    }

    return (
        <li className={styles.infoItem}>
            <img
                className={styles.uploadedImage}
                src={url}
                alt={name}
            />
            {onClickRemoveImage && (
                <AccessibleButton
                    tag="img"
                    className={styles.removeImage}
                    src={closeImage}
                    alt={name}
                    action={onClickRemoveImage}
                />
            )}
        </li>
    );
};
