import { IOrderAddress } from 'graphql/customer/customerOrdersInterfaces';

export const getAddressLine = (address: IOrderAddress): string => {
    const {
        street,
        postcode,
        city,
        region_id: code,
    } = address;
    const fullStreet = street.length > 1 ? street.join(' ') : street[0];
    return `${fullStreet} ${city} ${code} ${postcode}`;
};
