import { IRefundAgreement, IRmaRefundOption } from 'graphql/rma/rmaInterfaces';
import { IAgreementConfirmation } from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import {
    IOptionsState,
    IOptionsStatus,
    IOptionsAgreementsState,
} from '../create-returns/steps/step-refund-options/rma-refund-item/refund-options';

export const findRefundOption = (
    refundOptions: IRmaRefundOption[],
    findByValue: string,
): IRmaRefundOption|undefined => refundOptions.find(
    (option: IRmaRefundOption) => (option.rmaOption.value === findByValue),
);

export const getInvalidOptions = (
    refundOptions: IRmaRefundOption[],
    optionsStatus: IOptionsStatus,
) => refundOptions.filter((option: IRmaRefundOption) => {
    const { rmaOption } = option;
    const { value } = rmaOption;
    return optionsStatus[value] === 'invalid';
});

export const generateOptionsState = (refundOptions: IRmaRefundOption[]): IOptionsState => {
    const optionsState: IOptionsState = {};
    refundOptions.forEach((option: IRmaRefundOption) => {
        const { rmaOption } = option;
        const { value } = rmaOption;
        optionsState[value] = false;
    });
    return optionsState;
};

export const generateOptionsStatus = (
    refundOptions: IRmaRefundOption[],
    status?: 'default' | 'invalid',
): IOptionsStatus => {
    const optionsStatus: IOptionsStatus = {};
    refundOptions.forEach((option: IRmaRefundOption) => {
        const { rmaOption } = option;
        const { value } = rmaOption;
        optionsStatus[value] = status || 'default';
    });
    return optionsStatus;
};

export const generateOptionsAgreementsState = (refundOptions: IRmaRefundOption[]): IOptionsAgreementsState => {
    const initialAgreementsState: IOptionsAgreementsState = {};
    refundOptions.forEach((option: IRmaRefundOption) => {
        const { rmaOption, agreements } = option;
        const { value } = rmaOption;
        initialAgreementsState[value] = agreements
            ? agreements.map((agreement: IRefundAgreement) => ({ ...agreement, isAgreed: false }))
            : [];
    });
    return initialAgreementsState;
};

export const updateAgreements = (
    currentAgreements: IAgreementConfirmation[],
    agreementConfirmation: IAgreementConfirmation,
): IAgreementConfirmation[] => {
    const { id, isAgreed } = agreementConfirmation;
    let newAgreements: IAgreementConfirmation[] = [
        ...currentAgreements,
        agreementConfirmation,
    ];

    if (currentAgreements.length) {
        const foundAgreement: IAgreementConfirmation|undefined = currentAgreements.find(agreed => agreed.id === id);
        if (foundAgreement) {
            const updatedAgreement: IAgreementConfirmation = {
                ...foundAgreement,
                isAgreed,
            };
            newAgreements = currentAgreements.map(
                (current: IAgreementConfirmation) => (current.id === id ? updatedAgreement : current),
            );
        }
    }

    return newAgreements;
};
