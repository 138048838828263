import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IRadioButtonProps {
    id: string;
    name: string;
    value: string;
    isChecked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    contentAfterLabel?: JSX.Element;
    wrapperClassName?: string;
    labelClassName?: string;
    afterLabelClassName?: string;
    radioBtnClassName?: string;
    status?: 'default' | 'invalid';
}

export const RadioButton: React.FC<IRadioButtonProps> = (
    {
        id,
        name,
        value,
        isChecked,
        onChange,
        label,
        contentAfterLabel,
        wrapperClassName,
        labelClassName,
        afterLabelClassName,
        radioBtnClassName,
        status = 'default',
    },
) => (
    <div className={classes(styles.radioBtnWrap, (wrapperClassName || ''))}>
        <label htmlFor={id} className={classes(styles.label, (labelClassName || ''))}>
            <input
                type="radio"
                id={id}
                className={classes(styles.radioInput, (radioBtnClassName || ''))}
                onChange={onChange}
                checked={isChecked}
                name={name}
                value={value}
            />
            <span
                className={classes({
                    [styles.customRadioBtn]: true,
                    [styles.invalid]: status === 'invalid',
                })}
            />
            {label && (
                <span className={styles.labelText}>
                    <span>{label}</span>
                    {contentAfterLabel && (
                        <span className={classes(styles.contentAfterLabel, (afterLabelClassName || ''))}>
                            {contentAfterLabel}
                        </span>
                    )}
                </span>
            )}
        </label>
    </div>
);
