import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import {
    TypeRmaOrderItem,
    rmaOrderItemsVar,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import {
    ISalesLayoutConfig,
    currentOrderVar,
} from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { RmaRequestForm } from 'ui/component/sales-components/rma-request-form';
import { Sidebar } from './sidebar';
import { Steps } from './steps';
import styles from './style.css';

interface ICreateReturnsProps {
    config: ISalesLayoutConfig;
}

export const CreateReturns: React.FC<ICreateReturnsProps> = ({ config }) => {
    const t = usePhraseTranslater();
    const createReturnsOrder: ICustomerOrder|undefined = useReactiveVar(currentOrderVar);
    const rmaOrderItems: TypeRmaOrderItem[] = useReactiveVar(rmaOrderItemsVar);

    if (!createReturnsOrder) {
        return null;
    }

    const { salesConfig } = config;
    const { rmaConfig } = salesConfig;
    const { rmaFormUrl } = rmaConfig;

    const { number } = createReturnsOrder;
    const hasRmaItems = rmaOrderItems && rmaOrderItems.length > 0;

    return (
        <div className={classes(styles.createReturnsWrapper)}>
            <div className={styles.createReturnsContent}>
                {hasRmaItems && (
                    <Steps config={config} rmaItems={rmaOrderItems} />
                )}
                {!hasRmaItems && (
                    <div className={styles.noAvailableRmaItemsWrapper}>
                        <h3 className={styles.createReturnsTitle}>
                            {t('The order with number %1 has no available items to return.', number)}
                        </h3>
                        <RmaRequestForm
                            rmaFormUrl={rmaFormUrl}
                            rmaFormLabel={t('If you would like to create a return, you may use:')}
                        />
                    </div>
                )}
            </div>
            {
                hasRmaItems && (
                    <div className={styles.createReturnsSidebar}>
                        <Sidebar
                            config={config}
                        />
                    </div>
                )
            }
        </div>
    );
};
