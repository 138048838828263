import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    salesLayoutConfigVar,
    ISalesLayoutConfig,
} from '../../layouts/sales-layout/sales-layout-state';
import { SalesLayout } from '../../layouts/sales-layout';
import { IPageBreadcrumb } from '../../layouts/components/page-breadcrumbs';
import { CreateReturnsPageContent } from './create-returns-page-content';

const CreateReturnsPage: React.FC = () => {
    const t = usePhraseTranslater();

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);

    const { salesConfig } = config;
    const { customerOrdersUrl } = salesConfig;
    const pageTitle = t('Returns');

    const orderDetailsPageBreadcrumbs: IPageBreadcrumb[] = [
        {
            label: t('Your Orders'),
            url: customerOrdersUrl,
        },
        {
            label: pageTitle,
        },
    ];

    const createReturnsPageComponent: JSX.Element = (
        <CreateReturnsPageContent />
    );

    return (
        <SalesLayout
            pageKey="create-returns"
            pageTitle={pageTitle}
            pageContent={createReturnsPageComponent}
            pageBreadcrumbs={orderDetailsPageBreadcrumbs}
            showLeftSidebar={false}
            isGuestPage={false}
            splitOrderItems={false}
            loadItemsEligibleForReturn
        />
    );
};

export { CreateReturnsPage as default };
