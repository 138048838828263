import { IRmaShippingMethod } from 'graphql/rma/rmaInterfaces';
import { IMethodsState, IMethodsStatus } from '../create-returns/steps/step-shipping-options/rma-shipping-methods';

export const findShippingMethod = (
    shippingMethods: IRmaShippingMethod[],
    findByCode: string,
): IRmaShippingMethod|undefined => shippingMethods.find(method => method.code === findByCode);

export const getInvalidOptions = (
    shippingMethods: IRmaShippingMethod[],
    optionsStatus: IMethodsStatus,
) => shippingMethods.filter((option: IRmaShippingMethod) => {
    const { code } = option;
    return optionsStatus[code] === 'invalid';
});

export const generateOptionsState = (shippingMethods: IRmaShippingMethod[]): IMethodsState => {
    const optionsState: IMethodsState = {};
    shippingMethods.forEach((option: IRmaShippingMethod) => {
        const { code } = option;
        optionsState[code] = false;
    });
    return optionsState;
};

export const generateOptionsStatus = (
    shippingMethods: IRmaShippingMethod[],
    status?: 'default' | 'invalid',
): IMethodsStatus => {
    const optionsStatus: IMethodsStatus = {};
    shippingMethods.forEach((option: IRmaShippingMethod) => {
        const { code } = option;
        optionsStatus[code] = status || 'default';
    });
    return optionsStatus;
};
