import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { CreateReturns } from 'ui/component/rma/create-returns';
import {
    salesLayoutConfigVar,
    ISalesLayoutConfig,
} from '../../layouts/sales-layout/sales-layout-state';
import { PageContent } from '../../layouts/components/page-content';
import { InitializeCreateReturnsPage } from './create-returns-state';
import { LoaderContext } from './create-returns-loader';
import styles from './style.css';

export const CreateReturnsPageContent: React.FC = () => {
    const [createReturnsPageLoading, setCreateReturnsPageLoading] = useState(false);
    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const t = usePhraseTranslater();

    return (
        <LoaderContext.Provider value={{ createReturnsPageLoading, setCreateReturnsPageLoading }}>
            <InitializeCreateReturnsPage>
                <PageContent
                    pageTitle={t('Choose Items To Return')}
                    pageLoading={createReturnsPageLoading}
                    className={styles.createReturnsWrapper}
                    pageContentClassName={styles.createReturnsUi}
                >
                    <CreateReturns config={config} />
                </PageContent>
            </InitializeCreateReturnsPage>
        </LoaderContext.Provider>
    );
};
