import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IOrderAddress } from 'graphql/customer/customerOrdersInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { NoAddresses } from './no-addresses';
import { getAddressLine } from '../../../../util/shipping-address-resolver';
import styles from './style.css';

interface IRmaShippingAddressProps {
    config: ISalesLayoutConfig;
    rmaShippingAddress: IOrderAddress|null;
}

export const RmaShippingAddress: React.FC<IRmaShippingAddressProps> = (
    {
        rmaShippingAddress,
    },
) => {
    if (!rmaShippingAddress) {
        return (<NoAddresses />);
    }

    return (
        <div className={classes(styles.shippingAddressWrapper)}>
            <div className={styles.addressLine}>
                {getAddressLine(rmaShippingAddress)}
            </div>
        </div>
    );
};
