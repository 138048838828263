import React, { createContext, useContext } from 'react';

interface ICreateReturnsLoaderContext {
    createReturnsPageLoading: boolean;
    setCreateReturnsPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<ICreateReturnsLoaderContext | undefined>(undefined);

export const useCreateReturnsLoader = (
): ICreateReturnsLoaderContext => useContext(LoaderContext) as ICreateReturnsLoaderContext;
