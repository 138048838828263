import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import {
    IChosenReturnItem,
    currentStepVar,
    returnReasonsDataVar,
    STEP_SHIPPING_OPTIONS,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ProductImage } from 'ui/component/sales-components/product-image';
import { isChosenItemValid } from '../../../util/steps-validators';
import sidebarStyles from '../style.css';
import styles from './style.css';

interface IReturningItemsProps {
    config: ISalesLayoutConfig;
}

export const ReturningItems: React.FC<IReturningItemsProps> = () => {
    const t = usePhraseTranslater();

    const currentStepKey: string = useReactiveVar(currentStepVar);
    const chosenReturnItems: IChosenReturnItem[] = useReactiveVar(returnReasonsDataVar);
    const isShippingStep = currentStepKey === STEP_SHIPPING_OPTIONS;

    if (chosenReturnItems.length <= 0) {
        return null;
    }

    return (
        <div
            className={classes(sidebarStyles.sidebarContent, styles.returningItems)}
            data-test={`returning-items-${currentStepKey}`}
        >
            <div className={classes(styles.returningContent, styles.header)}>
                <span className={styles.label}>{t('Items You Are Returning')}</span>
            </div>
            <div
                className={classes(styles.returningContent, styles.chosenItems, {
                    [styles.shippingStep]: isShippingStep,
                })}
            >
                {chosenReturnItems.map((chosenReturnItem: IChosenReturnItem) => {
                    const { item } = chosenReturnItem;
                    const {
                        product_name: productName,
                        product_image_url: productImageUrl,
                    } = item;
                    return (
                        <ProductImage
                            productImageUrl={productImageUrl}
                            productName={productName}
                            className={classes(styles.imageWrapper, {
                                [styles.invalid]: !isChosenItemValid(chosenReturnItem),
                            })}
                        />
                    );
                })}
            </div>
        </div>
    );
};
