import { gql, TypedDocumentNode } from '@apollo/client';
import {
    IRequestReturnOutput,
    IGuestRequestReturnOutput,
    IRequestReturnInput,
} from './returnMutationsInterfaces';
import { RETURN_FIELDS } from '../../customer/fragments';

export const CUSTOMER_REQUEST_RETURN: TypedDocumentNode<IRequestReturnOutput, IRequestReturnInput> = gql`
    ${RETURN_FIELDS}
    mutation CUSTOMER_REQUEST_RETURN($input: RequestReturnInput!) {
        requestReturn(input: $input) {
            return {
                ...ReturnFields,
            }
        }
    }
`;

export const GUEST_REQUEST_RETURN: TypedDocumentNode<IGuestRequestReturnOutput, IRequestReturnInput> = gql`
    ${RETURN_FIELDS}
    mutation GUEST_REQUEST_RETURN($input: RequestReturnInput!) {
        guestRequestReturn(input: $input) {
            return {
                ...ReturnFields,
            }
        }
    }
`;
