import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IRefundAgreement } from 'graphql/rma/rmaInterfaces';
import { IAgreementConfirmation } from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { IOptionsState, IOptionsAgreementsState } from '../../refund-options';
import { Agreement } from './agreement';
import styles from './style.css';

interface IAgreementProps {
    value: string;
    optionsState: IOptionsState;
    optionsAgreementsState: IOptionsAgreementsState;
    agreements?: IRefundAgreement[]|null;
    onChangeAgreement: (
        value: string,
        agreementConfirmation: IAgreementConfirmation,
    ) => void;
    isRequired?: boolean;
}

export const Agreements: React.FC<IAgreementProps> = (
    {
        value,
        optionsState,
        optionsAgreementsState,
        agreements,
        onChangeAgreement,
        isRequired,
    },
) => {
    const getIsAgreed = (findByValue: string, agreement: IRefundAgreement): boolean => {
        const currentAgreements: IAgreementConfirmation[] = optionsAgreementsState[findByValue] || [];
        if (!currentAgreements.length) {
            return false;
        }

        const foundAgreement = currentAgreements.find(confirmed => confirmed.id === agreement.id);
        return foundAgreement ? foundAgreement.isAgreed : false;
    };

    if (!agreements?.length) {
        return null;
    }

    return (
        <div
            className={classes(styles.refundOptionAgreements, {
                [styles.fadeIn]: optionsState[value],
                [styles.fadeOut]: !optionsState[value],
            })}
        >
            {agreements.map(
                (agreement: IRefundAgreement) => (
                    <Agreement
                        value={value}
                        agreement={agreement}
                        isChecked={getIsAgreed(value, agreement)}
                        onChooseAgreement={onChangeAgreement}
                        isRequired={isRequired}
                    />
                ),
            )}
        </div>
    );
};
