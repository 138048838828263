import React from 'react';
import { IRmaRefundOption } from 'graphql/rma/rmaInterfaces';
import {
    IAgreementConfirmation,
    IChosenReturnItem,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { RadioButton } from 'ui/component/custom/radio-button';
import { Agreements } from './agreements';
import {
    IOptionsState,
    IOptionsStatus,
    IOptionsAgreementsState,
} from '../refund-options';
import styles from './style.css';

interface IRefundOptionProps {
    chosenReturnItem: IChosenReturnItem;
    rmaRefundOption: IRmaRefundOption;
    optionsState: IOptionsState;
    optionsStatus: IOptionsStatus;
    optionsAgreementsState: IOptionsAgreementsState;
    validateOnContinue: boolean;
    onChangeOption: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeAgreement: (value: string, agreementConfirmation: IAgreementConfirmation) => void;
}

export const RefundOption: React.FC<IRefundOptionProps> = (
    {
        chosenReturnItem,
        rmaRefundOption,
        optionsState,
        optionsStatus,
        optionsAgreementsState,
        validateOnContinue,
        onChangeOption,
        onChangeAgreement,
    },
) => {
    const { item } = chosenReturnItem;
    const { id } = item;
    const {
        rmaOption,
        title,
        description,
        agreements,
    } = rmaRefundOption;
    const { value } = rmaOption;

    return (
        <div className={styles.refundOptionWrapper}>
            <RadioButton
                id={`refund-option-id-${id}-${value}`}
                name={`refund_option_name_${id}-${value}`}
                value={value}
                label={title}
                wrapperClassName={styles.refundOptionRadioBtn}
                isChecked={optionsState[value]}
                onChange={onChangeOption}
                status={optionsStatus[value]}
            />
            <div className={styles.refundOption}>
                <div className={styles.refundOptionDetails}>
                    <div className={styles.refundOptionDescription}>{description}</div>
                    <Agreements
                        value={value}
                        optionsState={optionsState}
                        optionsAgreementsState={optionsAgreementsState}
                        agreements={agreements}
                        onChangeAgreement={onChangeAgreement}
                        isRequired={validateOnContinue}
                    />
                </div>
            </div>
        </div>
    );
};
