import { gql, TypedDocumentNode } from '@apollo/client';
import { IRmaRefundOption } from './rmaInterfaces';

export interface IRmaRefundOptionsOutput {
    rmaRefundOptions: IRmaRefundOption[];
}

export interface IRmaRefundOptionsInput {
    orderId: string;
}

export const GET_RMA_REFUND_OPTIONS: TypedDocumentNode<IRmaRefundOptionsOutput, IRmaRefundOptionsInput> = gql`
    query GET_RMA_REFUND_OPTIONS (
        $orderId: String!
    ) {
        rmaRefundOptions(
            orderId: $orderId
        ) {
            rmaOption {
                value
                label
            }
            title
            description
            paymentMethod {
                code
                title
                token {
                    publicHash
                    paymentMethodCode
                    type
                    details {
                        type
                        maskedCC
                        expirationDate
                    }
                }
            }
            agreements {
                id
                name
                content
                checkbox_text
            }
        }
    }
`;
