import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    IChosenReturnItem,
    returnReasonsDataVar,
    STEP_REFUND_OPTIONS,
    STEP_RETURN_REASONS,
    STEP_SHIPPING_OPTIONS,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { ProductImage } from 'ui/component/sales-components/product-image';
import { ProductLink } from 'ui/component/sales-components/product-details/product-link';
import { ReasonDetails, RefundDetails } from './step-content';
import styles from './style.css';

export interface IDetailsContentProps {
    stepKey: string;
}

export const DetailsContent: React.FC<IDetailsContentProps> = ({ stepKey }) => {
    const t = usePhraseTranslater();
    const chosenReturnItems: IChosenReturnItem[] = useReactiveVar(returnReasonsDataVar);

    const getStepContent = (chosenReturnItem: IChosenReturnItem): JSX.Element|null => {
        switch (stepKey) {
            case STEP_RETURN_REASONS:
                return (<ReasonDetails chosenReturnItem={chosenReturnItem} />);
            case STEP_REFUND_OPTIONS:
                return (<RefundDetails chosenReturnItem={chosenReturnItem} />);
            case STEP_SHIPPING_OPTIONS:
            default:
                return null;
        }
    };

    if (chosenReturnItems.length <= 0) {
        return null;
    }

    return (
        <>
            <div className={classes(styles.detailsContent, styles.chosenItemsContent)}>
                <div className={classes(styles.contentBlock, styles.chosenItemsHeader)}>
                    <span className={styles.label}>{t('See Details')}</span>
                </div>
                {chosenReturnItems.map((chosenReturnItem: IChosenReturnItem) => {
                    const { item, requestedQty } = chosenReturnItem;
                    const {
                        product_name: productName,
                        product_url: productUrl,
                        product_image_url: productImageUrl,
                    } = item;
                    const stepContent = getStepContent(chosenReturnItem);
                    return (
                        <div className={classes(styles.contentBlock, styles.chosenItem)}>
                            <ProductImage
                                productImageUrl={productImageUrl}
                                productName={productName}
                                className={classes(styles.detail, styles.imageWrapper)}
                                counter={requestedQty}
                                counterClassName={styles.chosenItemsCounter}
                            />
                            <div className={classes(styles.detail, styles.productDetails)}>
                                <ProductLink
                                    productName={productName}
                                    productUrl={productUrl}
                                    targetBlank
                                    underlineOnHover
                                    opacityOnHover
                                />
                                <div className={styles.chosenOptions}>
                                    {stepContent}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
