import { IRmaOption } from 'graphql/rma/rmaInterfaces';
import { IOption } from 'ui/component/custom/select';

export const generateQtyOptions = (quantity: number): IOption[] => {
    const qtyOptions: IOption[] = [];
    let i: number;
    for (i = 1; i <= quantity; i += 1) {
        qtyOptions.push({
            value: `${i}`,
            label: `${i}`,
        });
    }

    return qtyOptions;
};

export const getSelectedOption = (options: IOption[], findByValue: string) => options.find(
    (option: IOption) => {
        const { value } = option;
        return value === findByValue;
    },
);

export const getOptionByLabel = (options: IOption[], findByLabel: string) => options.find(
    (option: IOption) => {
        const { label } = option;
        return label === findByLabel;
    },
);

export const getOptionLabel = (options: IRmaOption[], findValue: string): string => {
    const foundOption = options.find(o => o.value === findValue);
    return foundOption ? foundOption.label : '';
};

export const getLowerCaseLabel = (
    options: IRmaOption[],
    findValue: string,
): string => getOptionLabel(options, findValue).toLowerCase();
