import React from 'react';
import { IUploadImagesResponse } from '@silkpwa/magento/api/files-uploader/files-uploader';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Image } from './image';
import styles from './style.css';

interface IUploadResultProps {
    uploadedImages: IUploadImagesResponse[];
    removeImageAction?: (imageToRemove: IUploadImagesResponse) => void;
}

export const UploadedImages: React.FC<IUploadResultProps> = ({ uploadedImages, removeImageAction }) => {
    const t = usePhraseTranslater();

    if (!uploadedImages.length) {
        return null;
    }

    return (
        <section className={styles.uploadedImagesSection}>
            <div className={styles.uploadedImagesLabel}>
                <p>{uploadedImages.length > 1 ? t('Your Product Images') : t('Your Product Image')}</p>
            </div>
            <ul className={styles.uploadedImagesInfo}>
                {uploadedImages.map(
                    (image: IUploadImagesResponse) => (
                        <Image
                            uploadedImage={image}
                            removeImageAction={removeImageAction}
                        />
                    ),
                )}
            </ul>
        </section>
    );
};
