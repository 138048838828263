import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import {
    IChosenReturnItem,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { LinkPopup } from 'ui/component/custom/link-popup';
import { IChosenReturnItemsProps } from '../chosen-return-items';
import { ProductDetails } from '../../product-details';
import { ProductImage } from '../../product-image';
import styles from './style.css';

interface IChosenReturnItemProps extends IChosenReturnItemsProps {
    chosenReturnItem: IChosenReturnItem;
}

export const ChosenReturnItem: React.FC<IChosenReturnItemProps> = (
    {
        config,
        chosenReturnItem,
        className,
        imageWidth,
        imageHeight,
    },
) => {
    const t = usePhraseTranslater();

    const { item } = chosenReturnItem;
    const {
        product_name: productName,
        product_image_url: productImageUrl,
    } = item;

    return (
        <div className={classes(styles.chosenItemWrapper, (className || ''))}>
            <ProductImage
                productImageUrl={productImageUrl}
                productName={productName}
                className={styles.rmaChosenProductImage}
                width={imageWidth}
                height={imageHeight}
            >
                <LinkPopup
                    linkLabel={t('Details')}
                    wrapperClassName={styles.detailsPopupLinkWrap}
                    linkClassName={styles.detailsPopupLink}
                    arrowClassName={styles.detailsPopupArrow}
                    contentClassName={styles.detailsPopupContent}
                    dataTest="chosen-rma-item-details"
                >
                    <ProductDetails
                        config={config}
                        item={item}
                        className={styles.chosenItemDetailsWrap}
                    />
                </LinkPopup>
            </ProductImage>
        </div>
    );
};
