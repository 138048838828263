import React, { useEffect, useState } from 'react';
import {
    useLazyQuery,
    useReactiveVar,
} from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { IOrderAddress } from 'graphql/customer/customerOrdersInterfaces';
import { IRmaShippingMethod } from 'graphql/rma/rmaInterfaces';
import {
    IRmaShippingMethodsInput,
    IChosenRmaShippingOrderItem,
    GET_RMA_SHIPPING_METHODS,
} from 'graphql/rma/rmaShippingMethods';
import {
    IChosenReturnItem,
    IChosenShippingOption,
    rmaShippingAddressVar,
    rmaShippingMethodsVar,
    returnReasonsDataVar,
    shippingOptionDataVar,
    STEP_SHIPPING_OPTIONS,
    updateRmaShippingMethods,
    updateShippingMethodsCache,
    getShippingMethodByHash,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { orderIdVar } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { LoadingImage } from 'ui/component/loading-image';
import { ChosenReturnItems } from 'ui/component/sales-components/chosen-return-items';
import { unsetShippingOptionsStepItem } from '../../../util/steps-data-processor';
import { IStepProps } from '../steps';
import { RmaShippingAddress } from './rma-shipping-address';
import { RmaShippingMethods } from './rma-shipping-methods';
import stepsStyles from '../style.css';
import styles from './style.css';

export const StepShippingOptions: React.FC<IStepProps> = (
    {
        config,
        currentStepKey,
    },
) => {
    const [methodsLoading, setMethodsLoading] = useState<boolean>(true);
    const [itemsHash, setItemsHash] = useState<string>('');
    const orderId: string = useReactiveVar(orderIdVar);
    const chosenReturnItems: IChosenReturnItem[] = useReactiveVar(returnReasonsDataVar);
    const rmaShippingAddress: IOrderAddress|null = useReactiveVar(rmaShippingAddressVar);
    const chosenShippingOption: IChosenShippingOption|undefined = useReactiveVar(shippingOptionDataVar);
    const currentShippingMethods: IRmaShippingMethod[] = useReactiveVar(rmaShippingMethodsVar);

    const t = usePhraseTranslater();

    const [getRmaShippingMethods] = useLazyQuery(GET_RMA_SHIPPING_METHODS, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateRmaShippingMethods([]);
            updateShippingMethodsCache(itemsHash, []);
        },
        onCompleted: (data): void => {
            const { rmaShippingMethods } = data;
            updateRmaShippingMethods(rmaShippingMethods);
            updateShippingMethodsCache(itemsHash, rmaShippingMethods);
        },
    });

    const getChosenRmaItems = (): IChosenRmaShippingOrderItem[] => chosenReturnItems.map(
        (item: IChosenReturnItem) => {
            const { item: orderItem, requestedQty: itemQty } = item;
            const { id: itemId, product_name: itemProductName } = orderItem;
            return {
                itemId,
                itemProductName,
                itemQty,
            };
        },
    );

    const loadRmaShippingMethods = async (chosenRmaItems: IChosenRmaShippingOrderItem[]) => {
        const variables: IRmaShippingMethodsInput = {
            orderId,
            chosenRmaItems,
        };
        const input = {
            variables,
        };
        await getRmaShippingMethods(input);
    };

    const checkShippingOption = (shippingMethods: IRmaShippingMethod[]): void => {
        if (!chosenShippingOption || !chosenShippingOption.chosenShippingMethod) {
            return;
        }

        const { chosenShippingMethod } = chosenShippingOption;
        const {
            code: chosenShippingMethodCode,
            price: chosenShippingMethodPrice,
        } = chosenShippingMethod;

        const foundShippingMethod: IRmaShippingMethod|undefined = shippingMethods.find(
            (method: IRmaShippingMethod) => {
                const { code } = method;
                return chosenShippingMethodCode === code;
            },
        );
        if (!foundShippingMethod) {
            unsetShippingOptionsStepItem();
            return;
        }

        const {
            price: foundShippingMethodPrice,
        } = foundShippingMethod;

        if (chosenShippingMethodPrice !== foundShippingMethodPrice) {
            unsetShippingOptionsStepItem();
        }
    };

    useEffect(() => {
        const chosenRmaItems: IChosenRmaShippingOrderItem[] = getChosenRmaItems();
        const chosenRmaItemsString = JSON.stringify(chosenRmaItems);
        const newHash = Buffer.from(chosenRmaItemsString).toString('base64');

        const cachedMethods = getShippingMethodByHash(newHash);
        if (cachedMethods !== undefined) {
            updateRmaShippingMethods(cachedMethods);
            return;
        }

        if (itemsHash === '' || itemsHash !== newHash) {
            setItemsHash(newHash);
            setMethodsLoading(true);

            loadRmaShippingMethods(chosenRmaItems).then(() => {
                setMethodsLoading(false);
            });
        }
    }, [chosenReturnItems]);

    useEffect(() => {
        if (!currentShippingMethods.length) {
            return;
        }

        checkShippingOption(currentShippingMethods);
    }, [currentShippingMethods]);

    if (currentStepKey !== STEP_SHIPPING_OPTIONS) {
        return null;
    }

    return (
        <div className={classes(stepsStyles.step, styles.shippingOptions)} data-test={`step-${currentStepKey}`}>
            <div className={classes(styles.shippingOptionRow, styles.shippingAddress)}>
                <RmaShippingAddress
                    config={config}
                    rmaShippingAddress={rmaShippingAddress}
                />
            </div>
            {rmaShippingAddress && (
                <div className={classes(styles.shippingOptionRow, styles.shippingDetails)}>
                    <ChosenReturnItems
                        config={config}
                        chosenReturnItems={chosenReturnItems}
                        className={styles.chosenReturnItemsWrap}
                        itemClassName={styles.chosenReturnItem}
                        imageHeight={130}
                    />
                    <div className={styles.shippingMethods}>
                        {methodsLoading && (
                            <LoadingImage parentClass={styles.methodsLoading} />
                        )}
                        {!methodsLoading && (
                            <>
                                {currentShippingMethods.length > 0 && (
                                    <RmaShippingMethods
                                        config={config}
                                        rmaShippingMethods={currentShippingMethods}
                                        chosenShippingOption={chosenShippingOption}
                                    />
                                )}
                                {!currentShippingMethods.length && (
                                    <div className={styles.noRmaShippingMethods}>
                                        <h3>{t('No Available RMA Shipping Methods.')}</h3>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
