import React, { useState, useRef } from 'react';
import { useClickOutside } from '@react-hooks-hub/use-click-outside';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import arrowDown from 'assets/images/down_arrow.svg';
import arrowUp from 'assets/images/up_arrow.svg';
import { SelectOption } from './select-option';
import styles from './style.css';

export interface IOption {
    value: string;
    label: string;
}

interface ISelectProps {
    options: IOption[];
    selected?: IOption;
    className?: string;
    placeholder?: string;
    placeholderClassName?: string;
    selectClassName?: string;
    placeholderBeforeChosenValue?: string;
    status?: 'default' | 'invalid';
    onChange?: (selected: IOption['value']) => void;
    onClose?: () => void;
}

export const Select: React.FC<ISelectProps> = (
    {
        options,
        selected,
        className,
        placeholder,
        placeholderClassName,
        selectClassName,
        placeholderBeforeChosenValue,
        status = 'default',
        onChange,
        onClose,
    },
) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const selectWrapperRef = useRef<HTMLDivElement>(null);
    const placeholderRef = useRef<HTMLDivElement>(null);

    const toggleSelect = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (isOutside: boolean): void => {
        if (isOutside && isOpen) {
            if (onClose) {
                onClose();
            }

            setIsOpen(false);
        }
    };

    useClickOutside([selectWrapperRef], handleClickOutside);

    const handleOptionClick = (value: IOption['value']) => {
        setIsOpen(false);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div
            className={classes(
                {
                    [styles.selectWrapper]: true,
                    [styles.open]: isOpen,
                },
                (className ?? ''),
            )}
            ref={selectWrapperRef}
        >
            <AccessibleButton
                tag="div"
                ref={placeholderRef}
                className={classes({
                    [styles.placeholder]: true,
                    [styles.invalid]: status === 'invalid',
                }, (placeholderClassName ?? ''))}
                action={toggleSelect}
                role="button"
                tabIndex={0}
            >
                {placeholderBeforeChosenValue && (
                    <div className={styles.placeholderLabel}>
                        {(selected?.label ? (`${placeholderBeforeChosenValue}: ${selected.label}`) : placeholder)}
                    </div>
                )}
                {!placeholderBeforeChosenValue && (
                    <div className={styles.placeholderLabel}>{selected?.label || placeholder}</div>
                )}
                <div className={styles.arrow}>
                    <img src={isOpen ? arrowUp : arrowDown} alt="" />
                </div>
            </AccessibleButton>
            {isOpen && (
                <div className={classes(styles.select, (selectClassName || ''))}>
                    {options.map(option => (
                        <SelectOption
                            key={option.value}
                            option={option}
                            selectedValue={selected?.value || ''}
                            onClick={handleOptionClick}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
