import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { usePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import { classes } from '@silkpwa/module/util/classes';
import {
    IChosenReturnItem,
    IChosenRefundOption,
    IChosenShippingOption,
    currentStepVar,
    rmaRefundOptionsVar,
    returnReasonsDataVar,
    refundOptionsDataVar,
    shippingOptionDataVar,
    STEP_SHIPPING_OPTIONS,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import { IRmaRefundOption, IRmaShippingMethod } from 'graphql/rma/rmaInterfaces';
import {
    calculateSubtotal,
    generateRefundLines,
} from '../../../util/refund-summary-options';
import sidebarStyles from '../style.css';
import styles from './style.css';

interface IRefundSummaryProps {
    config: ISalesLayoutConfig;
}

export interface IRefundLines {
    [key: string]: string;
}

export const RefundSummary: React.FC<IRefundSummaryProps> = () => {
    const [subtotalPrice, setSubtotalPrice] = useState<number|null>(null);
    const [shippingPrice, setShippingPrice] = useState<number|null>(null);
    const [totalPrice, setTotalPrice] = useState<number|null>(null);
    const [shippingMethodTitle, setShippingMethodTitle] = useState<string|null>(null);
    const [refundLines, setRefundLines] = useState<IRefundLines>({});

    const currentStepKey: string = useReactiveVar(currentStepVar);
    const refundOptions: IRmaRefundOption[] = useReactiveVar(rmaRefundOptionsVar);

    const chosenReturnItems: IChosenReturnItem[] = useReactiveVar(returnReasonsDataVar);
    const chosenRefundOptions: IChosenRefundOption[] = useReactiveVar(refundOptionsDataVar);
    const chosenShippingOption: IChosenShippingOption|undefined = useReactiveVar(shippingOptionDataVar);

    const shippingMethod: IRmaShippingMethod|undefined = chosenShippingOption?.chosenShippingMethod;

    const t = usePhraseTranslater();
    const roundPrice = usePriceRounder();
    const formatPrice = usePriceFormatter();

    const hasRefundOptions = refundOptions.length > 0;
    const hasRefundLines = Object.keys(refundLines).length > 0;

    const getPrice = (price: number) => formatPrice(roundPrice(price));

    const calculateSummary = () => {
        if (!chosenShippingOption || !shippingMethod) {
            return;
        }

        const calculatedSubtotal = calculateSubtotal(chosenReturnItems, chosenRefundOptions);
        const { title, price } = shippingMethod;
        const totalPrice = calculatedSubtotal;
        const newRefundLines = generateRefundLines(chosenReturnItems, chosenRefundOptions, getPrice, t);

        setSubtotalPrice(calculatedSubtotal);
        setShippingMethodTitle(title);
        setShippingPrice(price);
        setTotalPrice(totalPrice);
        setRefundLines(newRefundLines);
    };

    useEffect(() => {
        calculateSummary();
    }, [currentStepKey, chosenRefundOptions, shippingMethod]);

    if (currentStepKey !== STEP_SHIPPING_OPTIONS || !shippingMethod) {
        return null;
    }

    return (
        <div
            className={classes(sidebarStyles.sidebarContent, styles.refundSummary)}
            data-test={`refund-summary-${currentStepKey}`}
        >
            <div className={classes(styles.summaryDetails, styles.label, styles.title)}>{t('Refund Summary')}</div>
            <div className={classes(styles.summaryDetails, styles.estimatedTotals)}>
                {subtotalPrice !== null && (
                    <SummaryLine
                        amount={subtotalPrice}
                        label={t('Refund Subtotal')}
                        lineClassName={classes(styles.summaryDetail, styles.subtotal)}
                        lineRowClassName={styles.detailRow}
                        labelClassName={styles.label}
                        priceClassName={styles.price}
                    />
                )}
                {totalPrice !== null && (
                    <>
                        <SummaryLine
                            amount={totalPrice}
                            label={t('Total Estimated Refund')}
                            lineClassName={classes(styles.summaryDetail, styles.totalEstimate)}
                            lineRowClassName={styles.detailRow}
                            labelClassName={styles.label}
                            priceClassName={styles.price}
                        />
                        {hasRefundOptions && hasRefundLines && (
                            <div className={classes(styles.summaryDetail, styles.refundText)}>
                                {refundOptions.map((refundOption: IRmaRefundOption) => {
                                    const { rmaOption } = refundOption;
                                    const { value } = rmaOption;
                                    const currentLine: string | undefined = refundLines[value];
                                    if (!currentLine || !currentLine.length) {
                                        return null;
                                    }

                                    return (
                                        <div className={classes(styles.detailRow, styles.text)}>
                                            {currentLine}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </>
                )}
            </div>
            {shippingMethodTitle !== null && shippingPrice !== null && (
                <div className={classes(styles.summaryDetails, styles.shippingTotal)}>
                    <SummaryLine
                        amount={shippingPrice}
                        label={t('Shipping')}
                        subLabel={shippingMethodTitle}
                        lineClassName={classes(styles.summaryDetail, styles.shipping)}
                        lineRowClassName={styles.detailRow}
                        labelClassName={styles.label}
                        subLabelClassName={styles.shippingMethodLabel}
                        priceClassName={styles.price}
                    />
                </div>
            )}
        </div>
    );
};
