import React from 'react';
import { useReactiveVar } from '@apollo/client';
import {
    IChosenReturnItem,
    IChosenRefundOption,
    refundOptionsDataVar,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { getRefundOptionsItemData } from '../../../../../../util/steps-data-processor';
import styles from '../style.css';

export interface IRefundDetailsProps {
    chosenReturnItem: IChosenReturnItem;
}

export const RefundDetails: React.FC<IRefundDetailsProps> = ({ chosenReturnItem }) => {
    const chosenRefundOptions: IChosenRefundOption[] = useReactiveVar(refundOptionsDataVar);

    const { item } = chosenReturnItem;
    const { id } = item;

    const foundRefundOption = getRefundOptionsItemData(chosenRefundOptions, id);

    if (!foundRefundOption || !foundRefundOption.chosenOption) {
        return null;
    }

    const { chosenOption } = foundRefundOption;
    const { title } = chosenOption;

    return (
        <span className={styles.chosenRefundOption}>{title}</span>
    );
};
