import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { IDynamicWidget } from 'graphql/cms/dynamic-widgets';
import { Link } from 'ui/component/custom/link';
import { LinkPopup } from 'ui/component/custom/link-popup';
import { returnPoliciesVar } from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import styles from './style.css';

interface IPolicyProps {
    policyConfig: {
        hasEmbroideredItems: boolean;
        returnPolicyUrl: string;
        returnEmbPolicyUrl: string;
    };
}

export const Policy: React.FC<IPolicyProps> = ({ policyConfig }) => {
    const [policyContent, setPolicyContent] = useState<string>('');

    const t = usePhraseTranslater();
    const returnPolicyBlocks: IDynamicWidget[]|undefined = useReactiveVar(returnPoliciesVar);

    const {
        hasEmbroideredItems,
        returnPolicyUrl,
        returnEmbPolicyUrl,
    } = policyConfig;

    const regularLabel = t('Return Policy');
    const embLabel = t('Embroidered Product Return Policy');

    const processHtml = (html: string): { __html: string } => ({ __html: DOMPurify.sanitize(html) });
    const getBlockContent = (widgetBlocks: IDynamicWidget[], isEmb: boolean) => {
        const foundBlock = widgetBlocks.find(
            (block: IDynamicWidget) => {
                const { title } = block;
                return (!isEmb && title === regularLabel) || (isEmb && title === embLabel);
            },
        );
        return foundBlock ? foundBlock.content : '';
    };

    useEffect(() => {
        if (!returnPolicyBlocks) {
            return;
        }

        const content: string = getBlockContent(returnPolicyBlocks, hasEmbroideredItems);
        setPolicyContent(content);
    }, [returnPolicyBlocks]);

    return (
        <>
            {policyContent.length > 0 && (
                <LinkPopup
                    linkLabel={(hasEmbroideredItems ? embLabel : regularLabel)}
                    wrapperClassName={classes(styles.rmaItemPolicy, {
                        [styles.regularPolicy]: !hasEmbroideredItems,
                        [styles.embPolicy]: hasEmbroideredItems,
                    })}
                    linkClassName={styles.policyLink}
                    contentClassName={classes(styles.policyContentWrapper, {
                        [styles.emb]: hasEmbroideredItems,
                    })}
                    dataTest={(hasEmbroideredItems ? 'emb-return-policy-link' : 'return-policy-link')}
                >
                    {/* eslint-disable-next-line react/no-danger */}
                    <div className={styles.policyContent} dangerouslySetInnerHTML={processHtml(policyContent)} />
                </LinkPopup>
            )}
            {!policyContent.length && (
                <Link
                    label={(hasEmbroideredItems ? embLabel : regularLabel)}
                    href={(hasEmbroideredItems ? returnEmbPolicyUrl : returnPolicyUrl)}
                    target="_blank"
                    forceUnderline
                    opacityOnHover
                />
            )}
        </>
    );
};
