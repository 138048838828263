/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import {
    makeVar,
    ReactiveVar,
    useLazyQuery,
    useReactiveVar,
} from '@apollo/client';
import { useContainer } from '@silkpwa/redux';
import { IFilesUploader } from '@silkpwa/magento/api/files-uploader';
import { IUploadImagesResponse } from '@silkpwa/magento/api/files-uploader/files-uploader';
import {
    ICustomerOrder,
    IOrderAddress,
    IOrderItem,
    TypeSplitOrderItem,
} from 'graphql/customer/customerOrdersInterfaces';
import {
    IDynamicWidget,
    GET_DYNAMIC_WIDGETS,
} from 'graphql/cms/dynamic-widgets';
import { GET_RMA_SELECT_OPTIONS } from 'graphql/rma/rmaSelectOptions';
import { GET_RMA_REFUND_OPTIONS } from 'graphql/rma/rmaRefundOptions';
import {
    IRefundAgreement,
    IRmaOption,
    IRmaRefundOption,
    IRmaShippingMethod,
} from 'graphql/rma/rmaInterfaces';
import { useCreateReturnsLoader } from './create-returns-loader';
import {
    orderIdVar,
    currentOrderVar,
} from '../../layouts/sales-layout/sales-layout-state';

interface IInitializeCreateReturnsPageProps {
    children: React.ReactNode;
}

export type TypeRmaOrderItem = IOrderItem | TypeSplitOrderItem;

export const STEP_RETURN_REASONS = 'return-reasons';
export const STEP_REFUND_OPTIONS = 'refund-options';
export const STEP_SHIPPING_OPTIONS = 'shipping-options';
export const ACTION_CREATE_RETURNS = 'create-returns';

export interface IStepsValidation {
    [key: string]: boolean;
}

export interface IChosenReturnItem {
    item: IOrderItem|TypeSplitOrderItem;
    requestedQty: number;
    remainingQty: number;
    reason: string;
    condition: string;
    comment: string;
    uploadedImages: IUploadImagesResponse[];
    isChosenDamaged: boolean;
    rmaCommentRequired?: boolean;
}

export interface IAgreementConfirmation extends IRefundAgreement {
    isAgreed: boolean;
}

export interface IChosenRefundOption {
    chosenItemId: string;
    chosenOption?: IRmaRefundOption;
    agreements?: IAgreementConfirmation[];
}

export interface IChosenShippingOption {
    chosenShippingMethod?: IRmaShippingMethod;
}

interface IRmaShippingMethodsCache {
    [key: string]: IRmaShippingMethod[];
}

export const createReturnsSteps: string[] = [
    STEP_RETURN_REASONS,
    STEP_REFUND_OPTIONS,
    STEP_SHIPPING_OPTIONS,
];

export const initialStepsValidation: IStepsValidation = {
    [STEP_RETURN_REASONS]: false,
    [STEP_REFUND_OPTIONS]: false,
    [STEP_SHIPPING_OPTIONS]: false,
};

export const initialStepsVisibility: IStepsValidation = {
    [STEP_RETURN_REASONS]: true,
    [STEP_REFUND_OPTIONS]: false,
    [STEP_SHIPPING_OPTIONS]: false,
};

export const currentStepVar: ReactiveVar<string> = makeVar<string>('');
export const stepsValidationVar: ReactiveVar<IStepsValidation> = makeVar<IStepsValidation>(initialStepsValidation);
export const stepsVisibilityVar: ReactiveVar<IStepsValidation> = makeVar<IStepsValidation>(initialStepsVisibility);

export const returnReasonsDataVar: ReactiveVar<IChosenReturnItem[]> = makeVar<IChosenReturnItem[]>([]);
export const refundOptionsDataVar: ReactiveVar<IChosenRefundOption[]> = makeVar<IChosenRefundOption[]>([]);
export const shippingOptionDataVar: ReactiveVar<IChosenShippingOption|undefined> =
    makeVar<IChosenShippingOption|undefined>(undefined);

export const validateOnContinueVar: ReactiveVar<boolean> = makeVar<boolean>(false);
export const allStepsValidVar: ReactiveVar<boolean> = makeVar<boolean>(false);
export const rmaOrderItemsVar: ReactiveVar<TypeRmaOrderItem[]> = makeVar<TypeRmaOrderItem[]>([]);
export const rmaReasonOptionsVar: ReactiveVar<IRmaOption[]> = makeVar<IRmaOption[]>([]);
export const rmaConditionOptionsVar: ReactiveVar<IRmaOption[]> = makeVar<IRmaOption[]>([]);
export const rmaResolutionOptionsVar: ReactiveVar<IRmaOption[]> = makeVar<IRmaOption[]>([]);
export const rmaRefundOptionsVar: ReactiveVar<IRmaRefundOption[]> = makeVar<IRmaRefundOption[]>([]);
export const rmaShippingAddressVar: ReactiveVar<IOrderAddress|null> = makeVar<IOrderAddress|null>(null);
export const rmaShippingMethodsVar: ReactiveVar<IRmaShippingMethod[]> = makeVar<IRmaShippingMethod[]>([]);
export const rmaShippingMethodsCacheVar: ReactiveVar<IRmaShippingMethodsCache> = makeVar<IRmaShippingMethodsCache>({});
export const filesUploaderVar: ReactiveVar<IFilesUploader|null> = makeVar<IFilesUploader|null>(null);
export const returnPoliciesVar: ReactiveVar<IDynamicWidget[]|undefined> =
    makeVar<IDynamicWidget[]|undefined>(undefined);

const updateRmaOrderItems = (rmaOrderItems: TypeRmaOrderItem[]): void => {
    rmaOrderItemsVar(rmaOrderItems);
};

const updateRmaReasonOptions = (rmaReasonOptions: IRmaOption[]): void => {
    rmaReasonOptionsVar(rmaReasonOptions);
};

const updateRmaConditionOptions = (rmaConditionOptions: IRmaOption[]): void => {
    rmaConditionOptionsVar(rmaConditionOptions);
};

const updateRmaResolutionsOptions = (rmaResolutionsOptions: IRmaOption[]): void => {
    rmaResolutionOptionsVar(rmaResolutionsOptions);
};

const updateRmaRefundOptions = (rmaRefundOptions: IRmaRefundOption[]): void => {
    rmaRefundOptionsVar(rmaRefundOptions);
};

const updateRmaShippingAddress = (rmaShippingAddress: IOrderAddress|null): void => {
    rmaShippingAddressVar(rmaShippingAddress);
};

export const updateRmaShippingMethods = (rmaShippingMethods: IRmaShippingMethod[]): void => {
    rmaShippingMethodsVar(rmaShippingMethods);
};

export const updateShippingMethodsCache = (hashKey: string, rmaShippingMethods: IRmaShippingMethod[]): void => {
    rmaShippingMethodsCacheVar({
        ...rmaShippingMethodsCacheVar(),
        [hashKey]: rmaShippingMethods,
    });
};

export const getShippingMethodByHash = (hashKey: string): IRmaShippingMethod[]|undefined => {
    const methodsCache = rmaShippingMethodsCacheVar();
    return methodsCache[hashKey];
};

const setFilesUploader = (filesUploader: IFilesUploader|null): void => {
    filesUploaderVar(filesUploader);
};

const updateReturnPolicies = (returnPolicies?: IDynamicWidget[]|undefined): void => {
    returnPoliciesVar(returnPolicies);
};

/**
 * TODO: Use this method once Return Is Created
 */
export const resetStoredData = (): void => {
    stepsValidationVar({
        ...initialStepsValidation,
    });
    stepsVisibilityVar({
        ...initialStepsVisibility,
    });

    returnReasonsDataVar([]);
    refundOptionsDataVar([]);
    shippingOptionDataVar(undefined);
    rmaShippingMethodsCacheVar({});

    validateOnContinueVar(false);
    allStepsValidVar(false);
    updateRmaShippingAddress(null);
};

export const InitializeCreateReturnsPage: React.FC<IInitializeCreateReturnsPageProps> = ({ children }) => {
    const { createReturnsPageLoading, setCreateReturnsPageLoading } = useCreateReturnsLoader();

    const orderId = useReactiveVar(orderIdVar);
    const currentOrder = useReactiveVar(currentOrderVar);

    const filesUploader: IFilesUploader = useContainer<IFilesUploader>('filesUploader');
    setFilesUploader(filesUploader || null);

    const processCurrentOrderData = (currentOrder: ICustomerOrder) => {
        const {
            items_eligible_for_return: itemsEligibleForReturn,
            shipping_address: orderShippingAddress,
        } = currentOrder;
        const rmaItems: IOrderItem[] = itemsEligibleForReturn.filter(
            (item: IOrderItem) => {
                const {
                    eligible_for_return: isEligibleForReturn,
                    rma_available_qty: rmaAvailableQty,
                } = item;
                return isEligibleForReturn && rmaAvailableQty > 0;
            },
        );
        updateRmaOrderItems(rmaItems);
        updateRmaShippingAddress(orderShippingAddress);
    };

    const [getRmaSelectOptions] = useLazyQuery(GET_RMA_SELECT_OPTIONS, {
        variables: {},
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateRmaReasonOptions([]);
            updateRmaConditionOptions([]);
            updateRmaResolutionsOptions([]);
        },
        onCompleted: (data): void => {
            const { rmaSelectOptions } = data;
            const { reasons, conditions, resolutions } = rmaSelectOptions;

            updateRmaReasonOptions(reasons);
            updateRmaConditionOptions(conditions);
            updateRmaResolutionsOptions(resolutions);
        },
    });

    const [getRmaRefundOptions] = useLazyQuery(GET_RMA_REFUND_OPTIONS, {
        variables: {
            orderId,
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateRmaRefundOptions([]);
        },
        onCompleted: (data): void => {
            const { rmaRefundOptions } = data;
            updateRmaRefundOptions(rmaRefundOptions);
        },
    });

    const [getReturnPolicyWidget] = useLazyQuery(GET_DYNAMIC_WIDGETS, {
        variables: {
            location: 'RETURNPOLICY',
        },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: () => {
            updateReturnPolicies([]);
        },
        onCompleted: (data): void => {
            const { getDynamicWidgets } = data;
            updateReturnPolicies(getDynamicWidgets);
        },
    });

    const loadCreateReturnsPageData = async (currentOrder: ICustomerOrder) => {
        await getRmaSelectOptions();
        await getReturnPolicyWidget();
        await getRmaRefundOptions();
        processCurrentOrderData(currentOrder);
    };

    useEffect(() => {
        resetStoredData();
        if (!currentOrder) {
            return;
        }

        if (!createReturnsPageLoading) {
            setCreateReturnsPageLoading(true);
        }

        loadCreateReturnsPageData(currentOrder).finally(() => {
            setCreateReturnsPageLoading(false);
        });
    }, [currentOrder]);

    return (<>{children}</>);
};
