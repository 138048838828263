import { gql, TypedDocumentNode } from '@apollo/client';
import { IRmaSelectOptions } from './rmaInterfaces';

export interface IRmaSelectOptionsOutput {
    rmaSelectOptions: IRmaSelectOptions;
}

export const GET_RMA_SELECT_OPTIONS: TypedDocumentNode<IRmaSelectOptionsOutput, {}> = gql`
    query GET_RMA_SELECT_OPTIONS {
        rmaSelectOptions {
            reasons {
                value
                label
            }
            conditions {
                value
                label
            }
            resolutions {
                value
                label
            }
        }
    }
`;
