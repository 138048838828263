import React, { useState, useEffect } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface ICheckboxProps {
    wrapperClassName?: string;
    checkboxWrapClassName?: string;
    checkboxClassName?: string;
    checkmarkClassName?: string;
    checkboxTextClassName?: string;
    checkboxText?: string;
    isChecked?: boolean;
    onChange?: (isChecked: boolean) => void;
    required?: boolean;
    status?: 'default' | 'invalid';
}

export const Checkbox: React.FC<ICheckboxProps> = (
    {
        wrapperClassName,
        checkboxWrapClassName,
        checkboxClassName,
        checkmarkClassName,
        checkboxTextClassName,
        checkboxText,
        isChecked,
        onChange,
        required = false,
        status = 'default',
    },
) => {
    const [isActive, setIsActive] = useState<boolean>(isChecked ?? false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { checked } = target;

        setIsActive(checked);

        if (onChange) {
            onChange(checked);
        }
    };

    useEffect(() => {
        if (isChecked !== undefined) {
            setIsActive(isChecked);
        }
    }, [isChecked]);

    return (
        <div className={classes(styles.customCheckboxWrap, (wrapperClassName || ''))}>
            <div className={classes(styles.checkboxWrap, (checkboxWrapClassName || ''))}>
                <input
                    type="checkbox"
                    className={classes(styles.checkbox, (checkboxClassName || ''))}
                    onChange={handleOnChange}
                    checked={isActive}
                    required={required}
                />
                <span
                    className={classes(styles.checkmark, (checkmarkClassName || ''), {
                        [styles.invalid]: status === 'invalid',
                    })}
                />
            </div>
            {checkboxText && (
                <div className={classes(styles.checkboxText, (checkboxTextClassName || ''))}>{checkboxText}</div>
            )}
        </div>
    );
};
