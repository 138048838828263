import React, { useEffect, useState } from 'react';
import { IRefundAgreement } from 'graphql/rma/rmaInterfaces';
import { Checkbox } from 'ui/component/custom/checkbox';
import { IAgreementConfirmation } from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import styles from './style.css';

interface IAgreementProps {
    value: string;
    agreement: IRefundAgreement;
    onChooseAgreement: (
        value: string,
        agreementConfirmation: IAgreementConfirmation,
    ) => void;
    isChecked?: boolean;
    isRequired?: boolean;
}

export const Agreement: React.FC<IAgreementProps> = (
    {
        value,
        agreement,
        onChooseAgreement,
        isChecked,
        isRequired,
    },
) => {
    const [checkedState, setCheckedState] = useState<boolean>(isChecked ?? false);
    const [checkboxStatus, setCheckboxStatus] = useState<'default' | 'invalid'>('default');
    const { checkbox_text: checkboxText } = agreement;

    const validate = (isValid: boolean): void => {
        const status = (!isRequired || isValid) ? 'default' : 'invalid';
        setCheckboxStatus(status);
    };

    const onChangeAgreement = (checked: boolean): void => {
        const agreementConfirmation: IAgreementConfirmation = {
            ...agreement,
            isAgreed: checked,
        };
        setCheckedState(checked);
        onChooseAgreement(value, agreementConfirmation);
        validate(checked);
    };

    useEffect(() => {
        if (isChecked !== undefined) {
            setCheckedState(isChecked);
        }
    }, [isChecked]);

    useEffect(() => {
        if (isRequired) {
            validate(checkedState);
        }
    }, [isRequired]);

    return (
        <Checkbox
            wrapperClassName={styles.agreement}
            checkmarkClassName={styles.agreementCheckmark}
            checkboxText={checkboxText}
            isChecked={checkedState}
            onChange={onChangeAgreement}
            required={isRequired}
            status={checkboxStatus}
        />
    );
};
