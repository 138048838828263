import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import {
    IChosenShippingOption,
    currentStepVar,
    validateOnContinueVar,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { IRmaShippingMethod } from 'graphql/rma/rmaInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { RmaShippingMethod } from './rma-shipping-method';
import { setShippingOptionsStepItem } from '../../../../util/steps-data-processor';
import { isChosenShippingOptionValid } from '../../../../util/steps-validators';
import {
    findShippingMethod,
    getInvalidOptions,
    generateOptionsState,
    generateOptionsStatus,
} from '../../../../util/shipping-options-resolver';
import styles from './style.css';

interface IRmaShippingMethodsProps {
    config: ISalesLayoutConfig;
    rmaShippingMethods: IRmaShippingMethod[];
    chosenShippingOption: IChosenShippingOption|undefined;
}

export interface IMethodsState {
    [key: string]: boolean;
}

export interface IMethodsStatus {
    [key: string]: 'default' | 'invalid';
}

export const RmaShippingMethods: React.FC<IRmaShippingMethodsProps> = (
    {
        config,
        rmaShippingMethods,
        chosenShippingOption,
    },
) => {
    const [initialMethodsState, setInitialMethodsState] = useState<IMethodsState|null>(null);
    const [initialMethodsStatus, setInitialMethodsStatus] = useState<IMethodsStatus|null>(null);
    const [methodsState, setMethodsState] = useState<IMethodsState>({});
    const [methodsStatus, setMethodsStatus] = useState<IMethodsStatus>({});

    const currentStepKey: string = useReactiveVar(currentStepVar);
    const validateOnContinue: boolean = useReactiveVar(validateOnContinueVar);

    const createMethodsState = (): void => {
        const initialState: IMethodsState = generateOptionsState(rmaShippingMethods);
        setInitialMethodsState(initialState);
        setMethodsState(initialState);
    };

    const setSameMethodsStatusForAll = (status: 'default' | 'invalid'): void => {
        const resetToStatus: IMethodsStatus = generateOptionsStatus(rmaShippingMethods, status);
        setMethodsStatus(resetToStatus);
    };

    const validateChosenShippingOption = (validateShippingOption?: IChosenShippingOption) => {
        const shippingOption: IChosenShippingOption|undefined = validateShippingOption ?? chosenShippingOption;
        if (!shippingOption || !shippingOption.chosenShippingMethod) {
            setSameMethodsStatusForAll('invalid');
            return;
        }

        const isOptionValid = isChosenShippingOptionValid(shippingOption);
        const { chosenShippingMethod } = shippingOption;
        const { code } = chosenShippingMethod;

        const updatedMethodsStatus: IMethodsStatus = {
            ...initialMethodsStatus,
            [code]: (isOptionValid ? 'default' : 'invalid'),
        };

        setMethodsStatus(updatedMethodsStatus);
    };

    const createInitialMethodsStatus = (): void => {
        const initialStatus: IMethodsStatus = generateOptionsStatus(rmaShippingMethods);
        setInitialMethodsStatus(initialStatus);
    };

    const onChangeMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { value, checked } = target;

        setMethodsState({
            ...initialMethodsState,
            [value]: checked,
        });

        const chosenShippingMethod = findShippingMethod(rmaShippingMethods, value);
        const shippingOption: IChosenShippingOption = {
            chosenShippingMethod,
        };

        if (validateOnContinue && methodsStatus[value] === 'invalid') {
            validateChosenShippingOption(shippingOption);
        }

        setShippingOptionsStepItem(chosenShippingOption, shippingOption);
    };

    const getInitialChosenShippingOption = (): IChosenShippingOption => {
        let initialChosenShippingOption: IChosenShippingOption = {};
        if (chosenShippingOption && chosenShippingOption.chosenShippingMethod) {
            const { chosenShippingMethod } = chosenShippingOption;
            const { code } = chosenShippingMethod;

            setMethodsState({
                ...initialMethodsState,
                [code]: true,
            });

            initialChosenShippingOption = {
                ...chosenShippingOption,
                chosenShippingMethod,
            };
        }

        return initialChosenShippingOption;
    };

    useEffect(() => {
        createMethodsState();
        createInitialMethodsStatus();
        setSameMethodsStatusForAll('default');
    }, []);

    useEffect(() => {
        const initialShippingOption: IChosenShippingOption = getInitialChosenShippingOption();
        setShippingOptionsStepItem(chosenShippingOption, initialShippingOption);
    }, [currentStepKey]);

    useEffect(() => {
        if (!validateOnContinue) {
            const invalidOptions = getInvalidOptions(rmaShippingMethods, methodsStatus);
            if (invalidOptions.length > 0) {
                setSameMethodsStatusForAll('default');
            }

            return;
        }

        validateChosenShippingOption();
    }, [validateOnContinue, methodsState]);

    if (!rmaShippingMethods.length) {
        return null;
    }

    return (
        <div className={classes(styles.shippingMethodsWrapper)}>
            {rmaShippingMethods.map(
                (rmaShippingMethod: IRmaShippingMethod) => (
                    <RmaShippingMethod
                        config={config}
                        method={rmaShippingMethod}
                        methodsState={methodsState}
                        methodsStatus={methodsStatus}
                        onChangeMethod={onChangeMethod}
                        className={styles.shippingMethodWrapper}
                    />
                ),
            )}
        </div>
    );
};
