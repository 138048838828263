import {
    IChosenRefundOption,
    IChosenReturnItem,
    IChosenShippingOption,
    refundOptionsDataVar,
    returnReasonsDataVar,
    shippingOptionDataVar,
    STEP_REFUND_OPTIONS,
    STEP_RETURN_REASONS,
    STEP_SHIPPING_OPTIONS,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { getStepValidator } from './steps-validators';


/** STEP_1: RETURN REASONS: Get, Set and Validate */
export const findChosenItemById = (
    chosenItems: IChosenReturnItem[],
    chosenItemId: string,
): IChosenReturnItem|undefined => chosenItems.find(
    chosenItem => chosenItem.item.id === chosenItemId,
);

export const getReturnReasonsItemData = (
    chosenReturnItems: IChosenReturnItem[],
    itemId: string,
): IChosenReturnItem|undefined => chosenReturnItems.find(existingItem => existingItem.item.id === itemId);

export const setReturnReasonsStepData = (chosenItems: IChosenReturnItem[]): void => {
    returnReasonsDataVar([
        ...chosenItems,
    ]);
};

const setReturnReasonsStepDataAndValidate = (
    chosenReturnItems: IChosenReturnItem[],
): void => {
    setReturnReasonsStepData(chosenReturnItems);
    getStepValidator(STEP_RETURN_REASONS, chosenReturnItems).validate();
};

export const setReturnReasonsStepItem = (
    chosenReturnItems: IChosenReturnItem[],
    chosenItem: IChosenReturnItem,
): void => {
    const foundItems = chosenReturnItems.filter(existingItem => existingItem.item.id === chosenItem.item.id);
    if (foundItems.length > 0) {
        const foundItem = foundItems[0];
        const updatedItem = {
            ...foundItem,
            ...chosenItem,
        };
        const updatedChosenItems = chosenReturnItems.map(
            item => (item.item.id === chosenItem.item.id ? updatedItem : item),
        );
        setReturnReasonsStepDataAndValidate(updatedChosenItems);
    } else {
        const updatedChosenItems = [
            ...chosenReturnItems,
            chosenItem,
        ];
        setReturnReasonsStepDataAndValidate(updatedChosenItems);
    }
};


/** STEP_2: REFUND OPTIONS: Get, Set and Validate */
export const getRefundOptionsItemData = (
    chosenRefundOptions: IChosenRefundOption[],
    itemId: string,
): IChosenRefundOption|undefined => chosenRefundOptions.find(option => option.chosenItemId === itemId);

export const setRefundOptionsStepData = (chosenRefundOptions: IChosenRefundOption[]): void => {
    refundOptionsDataVar([
        ...chosenRefundOptions,
    ]);
};

const setRefundOptionsStepDataAndValidate = (
    chosenRefundOptions: IChosenRefundOption[],
): void => {
    setRefundOptionsStepData(chosenRefundOptions);
    getStepValidator(STEP_REFUND_OPTIONS, chosenRefundOptions).validate();
};

export const setRefundOptionsStepItem = (
    chosenRefundOptions: IChosenRefundOption[],
    chosenRefundOption: IChosenRefundOption,
): void => {
    const foundItems = chosenRefundOptions.filter(
        existingItem => existingItem.chosenItemId === chosenRefundOption.chosenItemId,
    );
    if (foundItems.length > 0) {
        const foundItem = foundItems[0];
        const updatedItem = {
            ...foundItem,
            ...chosenRefundOption,
        };
        const updatedChosenItems = chosenRefundOptions.map(
            item => (item.chosenItemId === chosenRefundOption.chosenItemId ? updatedItem : item),
        );
        setRefundOptionsStepDataAndValidate(updatedChosenItems);
    } else {
        const updatedChosenItems = [
            ...chosenRefundOptions,
            chosenRefundOption,
        ];
        setRefundOptionsStepDataAndValidate(updatedChosenItems);
    }
};


/** STEP_3: SHIPPING OPTIONS: Get, Set and Validate */
export const setShippingOptionsStepData = (newShippingOption: IChosenShippingOption): void => {
    const chosenShippingOption: IChosenShippingOption|undefined = shippingOptionDataVar();
    shippingOptionDataVar({
        ...chosenShippingOption,
        ...newShippingOption,
    });
};
export const unsetShippingOptionsStepData = (): void => {
    shippingOptionDataVar(undefined);
};

const setShippingOptionsStepDataAndValidate = (
    chosenShippingOption: IChosenShippingOption,
): void => {
    setShippingOptionsStepData(chosenShippingOption);
    getStepValidator(STEP_SHIPPING_OPTIONS, chosenShippingOption).validate();
};

export const setShippingOptionsStepItem = (
    chosenShippingOption: IChosenShippingOption|undefined,
    newShippingOption: IChosenShippingOption,
): void => {
    if (chosenShippingOption) {
        setShippingOptionsStepDataAndValidate({
            ...chosenShippingOption,
            ...newShippingOption,
        });
    } else {
        setShippingOptionsStepDataAndValidate(newShippingOption);
    }
};

export const unsetShippingOptionsStepItem = (): void => {
    unsetShippingOptionsStepData();
    getStepValidator(STEP_SHIPPING_OPTIONS).validate();
};


/** Steps Data Remove actions */
export const removeRefundOptionsStepItem = (removeItemId: string): void => {
    const chosenRefundOptions: IChosenRefundOption[] = refundOptionsDataVar();
    const updatedItems = chosenRefundOptions.filter(
        existingItem => existingItem.chosenItemId !== removeItemId,
    );
    setRefundOptionsStepDataAndValidate(updatedItems);
};

export const removeReturnReasonsStepItem = (
    chosenReturnItems: IChosenReturnItem[],
    removeItemId: string,
): void => {
    const updatedItems = chosenReturnItems.filter(existingItem => existingItem.item.id !== removeItemId);
    setReturnReasonsStepDataAndValidate(updatedItems);
    removeRefundOptionsStepItem(removeItemId);
};
