import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface INoAddressesProps {}

export const NoAddresses: React.FC<INoAddressesProps> = () => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.noAddressMessage}>
            <div className={styles.text}>{t('No Shipping Addresses Available. Please, try again later.')}</div>
        </div>
    );
};
