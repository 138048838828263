import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { IChosenReturnItem } from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { ChosenReturnItem } from './chosen-return-item';
import styles from './style.css';

export interface IChosenReturnItemsProps {
    config: ISalesLayoutConfig;
    chosenReturnItems?: IChosenReturnItem[];
    className?: string;
    itemClassName?: string;
    imageWidth?: number;
    imageHeight?: number;
}
export const ChosenReturnItems: React.FC<IChosenReturnItemsProps> = (
    {
        config,
        chosenReturnItems,
        className,
        itemClassName,
        imageWidth,
        imageHeight,
    },
) => {
    if (!chosenReturnItems || !chosenReturnItems.length) {
        return null;
    }

    return (
        <div className={classes(styles.chosenReturnItems, (className || ''))}>
            {chosenReturnItems.map(
                (chosenReturnItem: IChosenReturnItem) => (
                    <ChosenReturnItem
                        config={config}
                        chosenReturnItem={chosenReturnItem}
                        className={classes(styles.chosenItemWrapper, (itemClassName || ''))}
                        imageWidth={imageWidth}
                        imageHeight={imageHeight}
                    />
                ),
            )}
        </div>
    );
};
