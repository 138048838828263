import React, { useRef } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IOption } from '../select';
import styles from '../style.css';

interface ISelectOptionProps {
    option: IOption;
    selectedValue: IOption['value'];
    onClick: (value: IOption['value']) => void;
}

export const SelectOption: React.FC<ISelectOptionProps> = (
    {
        option,
        selectedValue,
        onClick,
    },
) => {
    const { value, label } = option;
    const optionRef = useRef<HTMLDivElement>(null);

    const handleClick = (clickedValue: IOption['value']): React.MouseEventHandler<HTMLDivElement> => () => {
        onClick(clickedValue);
    };

    const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, value: IOption['value']) => {
        const currentOption = optionRef.current;
        if ((document.activeElement === currentOption) && event.key === 'Enter') {
            onClick(value);
        }
    };

    return (
        <div
            role="button"
            tabIndex={0}
            ref={optionRef}
            className={classes({
                [styles.option]: true,
                [styles.selected]: value === selectedValue,
            })}
            data-value={value}
            onClick={handleClick(value)}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => handleEnterKeyDown(event, value)}
        >
            <span className={styles.optionLabel}>{label}</span>
        </div>
    );
};
