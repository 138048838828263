import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { IRmaRefundOption } from 'graphql/rma/rmaInterfaces';
import {
    IChosenReturnItem,
    IChosenRefundOption,
    IAgreementConfirmation,
    rmaRefundOptionsVar,
    validateOnContinueVar,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { setRefundOptionsStepItem, getRefundOptionsItemData } from '../../../../../util/steps-data-processor';
import { isChosenRefundOptionValid } from '../../../../../util/steps-validators';
import {
    findRefundOption,
    getInvalidOptions,
    generateOptionsState,
    generateOptionsStatus,
    generateOptionsAgreementsState,
    updateAgreements,
} from '../../../../../util/refund-options-resolver';
import { RefundOption } from './refund-option';
import styles from './style.css';

interface IRefundOptionsProps {
    chosenReturnItem: IChosenReturnItem;
    chosenRefundOptions: IChosenRefundOption[];
}

export interface IOptionsState {
    [key: string]: boolean;
}

export interface IOptionsStatus {
    [key: string]: 'default' | 'invalid';
}

export interface IOptionsAgreementsState {
    [key: string]: IAgreementConfirmation[];
}

export const RefundOptions: React.FC<IRefundOptionsProps> = (
    {
        chosenReturnItem,
        chosenRefundOptions,
    },
) => {
    const [initialOptionsState, setInitialOptionsState] = useState<IOptionsState|null>(null);
    const [initialOptionsStatus, setInitialOptionsStatus] = useState<IOptionsStatus|null>(null);
    const [optionsState, setOptionsState] = useState<IOptionsState>({});
    const [optionsStatus, setOptionsStatus] = useState<IOptionsStatus>({});
    const [optionsAgreementsState, setOptionsAgreementsState] = useState<IOptionsAgreementsState>({});

    const refundOptions: IRmaRefundOption[] = useReactiveVar(rmaRefundOptionsVar);
    const validateOnContinue: boolean = useReactiveVar(validateOnContinueVar);

    const { item } = chosenReturnItem;
    const { id: chosenItemId } = item;

    const createOptionsState = (): void => {
        const initialState: IOptionsState = generateOptionsState(refundOptions);
        setInitialOptionsState(initialState);
        setOptionsState(initialState);
    };

    const setSameOptionsStatusForAll = (status: 'default' | 'invalid'): void => {
        const resetToStatus: IOptionsStatus = generateOptionsStatus(refundOptions, status);
        setOptionsStatus(resetToStatus);
    };

    const createInitialOptionsStatus = (): void => {
        const initialStatus: IOptionsStatus = generateOptionsStatus(refundOptions);
        setInitialOptionsStatus(initialStatus);
    };

    const createOptionsAgreementsState = (): void => {
        const initialAgreementsState: IOptionsAgreementsState = generateOptionsAgreementsState(refundOptions);
        setOptionsAgreementsState(initialAgreementsState);
    };

    const validateChosenRefundOption = (validateChosenOption?: IChosenRefundOption): void => {
        const refundOption: IChosenRefundOption|undefined = validateChosenOption ??
            getRefundOptionsItemData(chosenRefundOptions, chosenItemId);

        if (!refundOption || !refundOption.chosenOption) {
            setSameOptionsStatusForAll('invalid');
            return;
        }

        const { chosenOption } = refundOption;
        const { rmaOption } = chosenOption;
        const { value } = rmaOption;

        const isOptionValid = isChosenRefundOptionValid(refundOption);

        const updatedOptionsStatus: IOptionsStatus = {
            ...initialOptionsStatus,
            [value]: (isOptionValid ? 'default' : 'invalid'),
        };

        setOptionsStatus(updatedOptionsStatus);
    };

    const onChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { value, checked } = target;

        setOptionsState({
            ...initialOptionsState,
            [value]: checked,
        });

        const chosenOption = findRefundOption(refundOptions, value);
        const agreements = chosenOption && chosenOption.agreements
            ? optionsAgreementsState[value]
            : undefined;

        const chosenRefundOption: IChosenRefundOption = {
            chosenItemId,
            chosenOption,
            agreements,
        };
        if (validateOnContinue && optionsStatus[value] === 'invalid') {
            validateChosenRefundOption(chosenRefundOption);
        }

        setRefundOptionsStepItem(chosenRefundOptions, chosenRefundOption);
    };

    const onChangeAgreement = (value: string, agreementConfirmation: IAgreementConfirmation): void => {
        const currentAgreements: IAgreementConfirmation[] = optionsAgreementsState[value] || [];
        const newAgreements = updateAgreements(currentAgreements, agreementConfirmation);

        setOptionsAgreementsState({
            ...optionsAgreementsState,
            [value]: newAgreements,
        });

        if (!optionsState[value]) {
            return;
        }

        const agreements = newAgreements;
        const chosenOption = findRefundOption(refundOptions, value);

        const chosenRefundOption: IChosenRefundOption = {
            chosenItemId,
            chosenOption,
            agreements,
        };
        if (validateOnContinue && optionsStatus[value] === 'invalid') {
            validateChosenRefundOption(chosenRefundOption);
        }

        setRefundOptionsStepItem(chosenRefundOptions, chosenRefundOption);
    };

    const getInitialChosenItem = (): IChosenRefundOption => {
        let initialChosenItem: IChosenRefundOption = { chosenItemId };
        const chosenRefundOption: IChosenRefundOption|undefined =
            getRefundOptionsItemData(chosenRefundOptions, chosenItemId);

        if (chosenRefundOption && chosenRefundOption.chosenOption) {
            const { chosenOption, agreements: confirmedAgreements } = chosenRefundOption;
            const { rmaOption, agreements } = chosenOption;
            const { value } = rmaOption;

            setOptionsState({
                ...initialOptionsState,
                [value]: true,
            });

            if (agreements && confirmedAgreements) {
                setOptionsAgreementsState({
                    ...optionsAgreementsState,
                    [value]: confirmedAgreements,
                });
            }

            initialChosenItem = {
                ...chosenRefundOption,
                chosenItemId,
            };
        }

        return initialChosenItem;
    };

    useEffect(() => {
        createOptionsState();
        createInitialOptionsStatus();
        setSameOptionsStatusForAll('default');
        createOptionsAgreementsState();
    }, []);

    useEffect(() => {
        const initialChosenItem = getInitialChosenItem();
        setRefundOptionsStepItem(chosenRefundOptions, initialChosenItem);
    }, []);

    useEffect(() => {
        if (!validateOnContinue) {
            const invalidOptions = getInvalidOptions(refundOptions, optionsStatus);
            if (invalidOptions.length > 0) {
                setSameOptionsStatusForAll('default');
            }

            return;
        }

        validateChosenRefundOption();
    }, [validateOnContinue, optionsState, optionsAgreementsState]);

    return (
        <div className={styles.refundOptionsWrapper}>
            {refundOptions.map(
                (option: IRmaRefundOption) => (
                    <RefundOption
                        chosenReturnItem={chosenReturnItem}
                        rmaRefundOption={option}
                        optionsState={optionsState}
                        optionsStatus={optionsStatus}
                        optionsAgreementsState={optionsAgreementsState}
                        validateOnContinue={validateOnContinue}
                        onChangeOption={onChangeOption}
                        onChangeAgreement={onChangeAgreement}
                    />
                ),
            )}
        </div>
    );
};
