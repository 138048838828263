import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import {
    IChosenReturnItem,
    IChosenRefundOption,
    returnReasonsDataVar,
    refundOptionsDataVar,
    STEP_REFUND_OPTIONS,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { IStepProps } from '../steps';
import { RmaRefundItem } from './rma-refund-item';
import { StepHeader } from '../step-header';
import stepsStyles from '../style.css';
import styles from './style.css';

export const StepRefundOptions: React.FC<IStepProps> = (
    {
        config,
        currentStepKey,
        currentStepTitle,
    },
) => {
    const chosenReturnItems: IChosenReturnItem[] = useReactiveVar(returnReasonsDataVar);
    const chosenRefundOptions: IChosenRefundOption[] = useReactiveVar(refundOptionsDataVar);

    if (currentStepKey !== STEP_REFUND_OPTIONS) {
        return null;
    }

    return (
        <div className={classes(stepsStyles.step, styles.refundOptions)}>
            {chosenReturnItems.map(
                (chosenReturnItem: IChosenReturnItem) => (
                    <div className={styles.chosenItemWrapper}>
                        <StepHeader
                            stepKey={currentStepKey}
                            stepTitle={currentStepTitle}
                            className={styles.refundOptionsHeader}
                            isActive
                        />
                        <RmaRefundItem
                            config={config}
                            chosenReturnItem={chosenReturnItem}
                            chosenRefundOptions={chosenRefundOptions}
                        />
                    </div>
                ),
            )}
        </div>
    );
};
