import { LocationArgType } from '@silkpwa/module/util/location-to-string';
import { Navigate } from '@silkpwa/module/router/use-navigate';

export interface INavigateToUrlProps {
    navigate: Navigate;
    url: URL|string;
}

export const navigateToUrl = (
    { navigate, url }: INavigateToUrlProps,
): void => {
    const navigateUrl: URL = typeof url === 'string' ? new URL(url) : url;
    const { pathname, search, hash } = navigateUrl;
    const newLocation: LocationArgType = {
        pathname,
        search,
        hash,
    };
    navigate(newLocation);
};
