import { gql, TypedDocumentNode } from '@apollo/client';
import { IRmaShippingMethod } from './rmaInterfaces';

export interface IChosenRmaShippingOrderItem {
    itemId: string;
    itemProductName: string;
    itemQty: number;
}
export interface IRmaShippingMethodsInput {
    orderId: string;
    chosenRmaItems: IChosenRmaShippingOrderItem[];
}

export interface IRmaShippingMethodsOutput {
    rmaShippingMethods: IRmaShippingMethod[];
}

export const GET_RMA_SHIPPING_METHODS: TypedDocumentNode<IRmaShippingMethodsOutput, IRmaShippingMethodsInput> = gql`
    query GET_RMA_SHIPPING_METHODS (
        $orderId: String!
        $chosenRmaItems: [ChosenRmaOrderItem!]
    ) {
        rmaShippingMethods(
            orderId: $orderId
            chosenRmaItems: $chosenRmaItems
        ) {
            available
            code
            title
            price
            desc
        }
    }
`;
