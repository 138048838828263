import {
    IChosenRefundOption,
    IChosenReturnItem,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { IRmaRefundOption } from 'graphql/rma/rmaInterfaces';
import { IRefundLines } from '../create-returns/sidebar/refund-summary';
import { findChosenItemById } from './steps-data-processor';

const EXCHANGE = 'Exchange';
const REFUND = 'Refund';
const STORE_CREDIT = 'Store Credit';
const GIFT_CARD = 'Gift Card';

interface IRefundPrices {
    [key: string]: number;
}

const getPricesPerRefundOption = (
    chosenReturnItems: IChosenReturnItem[],
    chosenOptions: IChosenRefundOption[],
): IRefundPrices => {
    const prices: IRefundPrices = {};
    chosenOptions.forEach((chosenRefundOption: IChosenRefundOption) => {
        const { chosenItemId, chosenOption } = chosenRefundOption;
        const chosenItem = findChosenItemById(chosenReturnItems, chosenItemId);
        if (chosenOption && chosenItem) {
            const { item, requestedQty } = chosenItem;
            const { product_sale_price: salePrice } = item;
            const { value: price } = salePrice;
            const { rmaOption } = chosenOption;
            const { value, label } = rmaOption;
            if (label !== EXCHANGE) {
                const previousPrice = prices[value] || 0.00;
                prices[value] = (previousPrice + (price * requestedQty));
            }
        }
    });
    return prices;
};

const getRmaOptionText = (
    t: any,
    chosenOption?: IRmaRefundOption,
    price?: string,
): string => {
    if (!chosenOption || !price) {
        return '';
    }

    const { rmaOption, paymentMethod } = chosenOption;
    const { label } = rmaOption;

    switch (label) {
        case REFUND:
            if (paymentMethod) {
                const { token, title } = paymentMethod;
                if (token && token?.details?.maskedCC) {
                    const { details } = token;
                    const { maskedCC } = details;
                    return t('%1 will be added to your credit card ending in %2', price, maskedCC);
                }

                return t('%1 will be added using %2', price, title);
            }

            return '';
        case STORE_CREDIT:
            return t('%1 will be added to your %2', price, STORE_CREDIT);
        case GIFT_CARD:
            return t('%1 will be added to your %2', price, GIFT_CARD);
        case EXCHANGE:
        default:
            return '';
    }
};

const getChosenOption = (
    chosenOptions: IChosenRefundOption[],
    value: string,
): IRmaRefundOption|undefined => {
    const foundOption: IChosenRefundOption|undefined = chosenOptions.find(
        (chosenRefundOption: IChosenRefundOption) => {
            const { chosenOption } = chosenRefundOption;
            return chosenOption && chosenOption.rmaOption.value === value;
        },
    );
    return foundOption ? foundOption.chosenOption : undefined;
};

export const generateRefundLines = (
    chosenReturnItems: IChosenReturnItem[],
    chosenOptions: IChosenRefundOption[],
    getFormattedPrice: (price: number) => string,
    t: any,
): IRefundLines => {
    const newRefundLines: IRefundLines = {};
    const refundPrices: IRefundPrices = getPricesPerRefundOption(chosenReturnItems, chosenOptions);
    const valueKeys = Object.keys(refundPrices);

    valueKeys.forEach((keyValue: string) => {
        const price = refundPrices[keyValue];
        const finalPrice = getFormattedPrice(price);
        const chosenOption = getChosenOption(chosenOptions, keyValue);
        if (chosenOption) {
            const { rmaOption } = chosenOption;
            const { value } = rmaOption;
            newRefundLines[value] = getRmaOptionText(t, chosenOption, finalPrice);
        }
    });
    return newRefundLines;
};

export const calculateSubtotal = (
    chosenReturnItems: IChosenReturnItem[],
    chosenOptions: IChosenRefundOption[],
): number => {
    let calculatedSubtotal = 0.00;
    chosenOptions.forEach((option: IChosenRefundOption) => {
        const { chosenItemId, chosenOption } = option;
        if (chosenOption) {
            const { rmaOption } = chosenOption;
            const { label } = rmaOption;
            const chosenItem = findChosenItemById(chosenReturnItems, chosenItemId);
            if (label !== EXCHANGE && chosenItem) {
                const { item, requestedQty } = chosenItem;
                const { product_sale_price: salePrice } = item;
                const { value } = salePrice;
                calculatedSubtotal += (value * requestedQty);
            }
        }
    });
    return calculatedSubtotal;
};
