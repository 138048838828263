import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { STEP_SHIPPING_OPTIONS } from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { SeeDetails } from './see-details';
import styles from '../style.css';

export interface IStepHeader {
    stepKey: string;
    stepTitle: string;
    isActive: boolean;
    onClickStepHeader?: (stepKey: string) => void;
    className?: string;
}

export const StepHeader: React.FC<IStepHeader> = (
    {
        stepKey,
        stepTitle,
        isActive,
        onClickStepHeader,
        className,
    },
) => {
    const isClickable = onClickStepHeader !== undefined;
    const handleClickOnHeader = (
        event: React.MouseEvent<HTMLButtonElement>|React.KeyboardEvent<HTMLButtonElement>,
        stepKey: string,
    ): void => {
        event.preventDefault();
        if (onClickStepHeader) {
            onClickStepHeader(stepKey);
        }
    };

    const notShipping = stepKey !== STEP_SHIPPING_OPTIONS;

    return (
        <div
            className={classes(styles.stepHeader, (className || ''), {
                [styles.active]: isActive,
                [styles.notShipping]: notShipping,
            })}
            data-test={`step-header-${stepKey}`}
        >
            <button
                type="button"
                className={classes(styles.stepHeaderButton, {
                    [styles.clickable]: isClickable,
                })}
                tabIndex={0}
                key={stepKey}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClickOnHeader(event, stepKey)}
                onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => handleClickOnHeader(event, stepKey)}
            >
                <span className={styles.stepHeaderTitle}>{stepTitle}</span>
            </button>
            {isClickable && !isActive && (<SeeDetails stepKey={stepKey} />)}
        </div>
    );
};
