import { useContainer } from '@silkpwa/redux';
import { LocationArgType } from '@silkpwa/module/util/location-to-string';
import { useDispatch } from 'react-redux';
import { Router } from './router';

export type Navigate = (newLocation: LocationArgType, isRedirect?: boolean) => void;

export const useNavigate = (): Navigate => {
    const router = useContainer<Router>('router');
    const dispatch = useDispatch();

    return (newLocation: LocationArgType, isRedirect?: boolean) => {
        dispatch(router.navigate(newLocation, isRedirect));
    };
};
