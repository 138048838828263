import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import {
    IChosenReturnItem,
    IChosenRefundOption,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ChosenReturnItem } from 'ui/component/sales-components/chosen-return-items/chosen-return-item';
import { RefundOptions } from './refund-options';
import styles from './style.css';

interface IRmaRefundItemProps {
    config: ISalesLayoutConfig;
    chosenReturnItem: IChosenReturnItem;
    chosenRefundOptions: IChosenRefundOption[];
}

export const RmaRefundItem: React.FC<IRmaRefundItemProps> = (
    {
        config,
        chosenReturnItem,
        chosenRefundOptions,
    },
) => (
    <div className={styles.rmaChosenItem}>
        <div className={classes(styles.rmaChosenItemContent, styles.rmaChosenItemDetails)}>
            <ChosenReturnItem
                config={config}
                chosenReturnItem={chosenReturnItem}
                className={styles.chosenItemWrap}
                imageHeight={200}
            />
        </div>
        <div className={classes(styles.rmaChosenItemContent, styles.chosenItemRefundOptions)}>
            <RefundOptions
                chosenReturnItem={chosenReturnItem}
                chosenRefundOptions={chosenRefundOptions}
            />
        </div>
    </div>
);
