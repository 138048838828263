import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { IFilesUploader } from '@silkpwa/magento/api/files-uploader';
import { IUploadImagesResponse } from '@silkpwa/magento/api/files-uploader/files-uploader';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { IOrderItem, TypeSplitOrderItem } from 'graphql/customer/customerOrdersInterfaces';
import { IRmaOption } from 'graphql/rma/rmaInterfaces';
import {
    IChosenReturnItem,
    rmaReasonOptionsVar,
    rmaConditionOptionsVar,
    filesUploaderVar,
    STEP_RETURN_REASONS,
    validateOnContinueVar,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { EmbroideryDetails } from 'ui/component/orders-list/order/items/item/emb-details';
import { MultipleImageUploader } from 'ui/component/multiple-image-uploader';
import { UploadedImages } from 'ui/component/multiple-image-uploader/uploaded-images';
import { IOption, Select } from 'ui/component/custom/select';
import { Link } from 'ui/component/custom/link';
import { Checkbox } from 'ui/component/custom/checkbox';
import { ProductDetails } from 'ui/component/sales-components/product-details';
import { ProductImage } from 'ui/component/sales-components/product-image';
import {
    generateQtyOptions,
    getSelectedOption,
    getOptionByLabel,
    getLowerCaseLabel,
} from '../../../../util/rma-select-options';
import {
    setReturnReasonsStepItem,
    getReturnReasonsItemData,
    removeReturnReasonsStepItem,
} from '../../../../util/steps-data-processor';
import {
    getStepValidator,
    isRequestedQtyValid,
    isReasonValid,
    isConditionValid,
    isCommentValid,
} from '../../../../util/steps-validators';
import { Policy } from './policy';
import styles from './style.css';

interface IRmaItemProps {
    config: ISalesLayoutConfig;
    rmaItem: IOrderItem|TypeSplitOrderItem;
    chosenReturnItems: IChosenReturnItem[];
}

interface IChosenReturnItemUpdateOptions {
    updateRequestedQty?: number;
    updateRemainingQty?: number;
    updateReason?: string;
    updateCondition?: string;
    updateComment?: string;
    updateUploadedImages?: IUploadImagesResponse[];
    updateIsChosenDamaged?: boolean;
}

interface IUploadError {
    message: string;
}

export const RmaItem: React.FC<IRmaItemProps> = (
    {
        config,
        rmaItem,
        chosenReturnItems,
    },
) => {
    const [item] = useState(rmaItem);
    const [isRmaItemChecked, setIsRmaItemChecked] = useState(false);

    const [requestedQty, setRequestedQty] = useState(0);
    const [remainingQty, setRemainingQty] = useState(0);
    const [reason, setReason] = useState('');
    const [condition, setCondition] = useState('');
    const [isChosenDamaged, setIsChosenDamaged] = useState<boolean>(false);

    const [uploadedImages, setUploadedImages] = useState<IUploadImagesResponse[]>([]);
    const [uploadErrors, setUploadErrors] = useState<IUploadError[]>([]);
    const [uploadInfoMessage, setUploadInfoMessage] = useState<string>('');
    const [showUploadErrors, setShowUploadErrors] = useState<boolean>(false);
    const [showUploadInfoMessage, setShowUploadInfoMessage] = useState<boolean>(false);

    const commentMaxLength = 200;
    const maxCountImagesLimit = 10;
    const maxImageFileSizeMB = 5;

    const [comment, setComment] = useState('');
    const [remainingCommentLength, setRemainingCommentLength] = useState(commentMaxLength);
    const [timer, setTimer] = useState(null as any);

    const [requestedQtyStatus, setRequestedQtyStatus] = useState<'default' | 'invalid'>('default');
    const [reasonStatus, setReasonStatus] = useState<'default' | 'invalid'>('default');
    const [conditionStatus, setConditionStatus] = useState<'default' | 'invalid'>('default');
    const [commentStatus, setCommentStatus] = useState<'default' | 'invalid'>('default');

    const filesUploader: IFilesUploader|null = useReactiveVar(filesUploaderVar);
    const rmaReasonOptions: IRmaOption[] = useReactiveVar(rmaReasonOptionsVar);
    const rmaConditionOptions: IRmaOption[] = useReactiveVar(rmaConditionOptionsVar);
    const validateOnContinue: boolean = useReactiveVar(validateOnContinueVar);

    const t = usePhraseTranslater();

    const { salesConfig } = config;
    const { productSupportUrl, rmaConfig } = salesConfig;
    const { rmaCommentRequired } = rmaConfig;
    const {
        id: rmaItemId,
        product_name: productName,
        product_image_url: productImageUrl,
        rma_available_qty: rmaAvailableQty,
        embroideryItems,
    } = rmaItem;
    const hasEmbroideredItems = !!(embroideryItems && embroideryItems.length > 0);
    const policyConfig = {
        hasEmbroideredItems,
        returnPolicyUrl: `${productSupportUrl}#return-policy`,
        returnEmbPolicyUrl: `${productSupportUrl}#embroidery`,
    };
    const requestedQtyOptions: IOption[] = generateQtyOptions(rmaAvailableQty);

    const updateChosenReturnItem = (updateItemOptions: IChosenReturnItemUpdateOptions) => {
        const {
            updateRequestedQty,
            updateRemainingQty,
            updateReason,
            updateCondition,
            updateComment,
            updateUploadedImages,
            updateIsChosenDamaged,
        } = updateItemOptions;
        const updatedChosenReturnItem: IChosenReturnItem = {
            item,
            requestedQty: updateRequestedQty ?? requestedQty,
            remainingQty: updateRemainingQty ?? remainingQty,
            reason: updateReason ?? reason,
            condition: updateCondition ?? condition,
            comment: updateComment ?? comment,
            uploadedImages: updateUploadedImages ?? uploadedImages,
            isChosenDamaged: updateIsChosenDamaged ?? isChosenDamaged,
        };

        setReturnReasonsStepItem(chosenReturnItems, updatedChosenReturnItem);
    };

    const getChosenReturnItem = (): IChosenReturnItem => ({
        item,
        requestedQty,
        remainingQty,
        reason,
        condition,
        comment,
        uploadedImages,
        isChosenDamaged,
        rmaCommentRequired,
    });

    const chooseRmaItem = (checked: boolean): void => {
        setIsRmaItemChecked(checked);

        if (!checked) {
            removeReturnReasonsStepItem(chosenReturnItems, rmaItem.id);
            return;
        }

        const updatedChosenReturnItem: IChosenReturnItem = getChosenReturnItem();
        setReturnReasonsStepItem(chosenReturnItems, updatedChosenReturnItem);
    };

    const onClickRequestedQty = (quantity: string): void => {
        const updateRequestedQty = parseInt(quantity, 10);
        const updateRemainingQty = rmaAvailableQty - updateRequestedQty;

        if (validateOnContinue && requestedQtyStatus === 'invalid') {
            setRequestedQtyStatus('default');
        }

        setRequestedQty(updateRequestedQty);
        setRemainingQty(updateRemainingQty);
        if (isRmaItemChecked) {
            updateChosenReturnItem({
                updateRequestedQty,
                updateRemainingQty,
            });
        }
    };

    const onClickReason = (reasonValue: string): void => {
        if (validateOnContinue && reasonStatus === 'invalid') {
            setReasonStatus('default');
        }

        setReason(reasonValue);
        updateChosenReturnItem({
            updateReason: reasonValue,
        });
    };

    const onClickCondition = (conditionValue: string): void => {
        if (validateOnContinue && conditionStatus === 'invalid') {
            setConditionStatus('default');
        }

        setCondition(conditionValue);
        if (isRmaItemChecked) {
            updateChosenReturnItem({
                updateCondition: conditionValue,
            });
        }
    };

    const onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        event.preventDefault();
        const { target } = event;
        const { value: updateComment } = target;
        const { length } = updateComment;

        const newRemainingLength = commentMaxLength - length;

        if (validateOnContinue && rmaCommentRequired) {
            setCommentStatus(length > 0 ? 'default' : 'invalid');
        }

        setRemainingCommentLength(newRemainingLength);
        setComment(updateComment);

        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }

        const newTimer = setTimeout(() => {
            updateChosenReturnItem({
                updateComment,
            });
        }, 500);

        setTimer(newTimer);
    };

    const clearErrors = (delay = 0): void => {
        setShowUploadErrors(false);
        if (!uploadErrors.length) {
            return;
        }

        setTimeout(() => {
            setUploadErrors([]);
        }, delay);
    };

    const showErrors = (delay = 0): void => {
        setTimeout(() => {
            setShowUploadErrors(true);
        }, delay);
    };

    const clearInfo = (delay = 0): void => {
        setShowUploadInfoMessage(false);
        if (!uploadInfoMessage.length) {
            return;
        }

        setTimeout(() => {
            setUploadInfoMessage('');
        }, delay);
    };

    const showInfo = (delay = 0): void => {
        setTimeout(() => {
            setShowUploadInfoMessage(true);
        }, delay);
    };

    const clearAllMessages = (delay = 0): void => {
        clearErrors(delay);
        clearInfo(delay);
    };

    interface IFilteredInputImages {
        addedImagesCount: number;
        skippedImagesCount: number;
        filteredImages: File[];
    }

    const filterInputImages = (imagesList: FileList, currentUploadErrors: IUploadError[]): IFilteredInputImages => {
        const result: IFilteredInputImages = {
            addedImagesCount: 0,
            skippedImagesCount: 0,
            filteredImages: [],
        };

        for (let i = 0; i < imagesList.length; i += 1) {
            const image: File = imagesList[i];
            const { size, name } = image;
            const imageFileSizeMB = size / (1024 * 1024);
            if (imageFileSizeMB <= maxImageFileSizeMB) {
                result.filteredImages.push(imagesList[i]);
                result.addedImagesCount += 1;
            } else {
                const maxSizeExceededMessage = t(
                    'File with the name "%1" exceeds the maximum allowed size of %2 MB (uploading is skipped).',
                    name,
                    maxImageFileSizeMB,
                );
                currentUploadErrors.push({
                    message: maxSizeExceededMessage,
                });
                result.skippedImagesCount += 1;
            }
        }

        return result;
    };

    const setErrorAndReject = (
        message: string,
        currentUploadErrors: IUploadError[],
        reject?: Function,
    ): Promise<void> => {
        currentUploadErrors.push({
            message,
        });
        setUploadErrors([
            ...currentUploadErrors,
        ]);
        showErrors(1000);
        if (reject) {
            return reject();
        }

        return Promise.reject();
    };

    const uploadImagesAction = async (files: FileList): Promise<void> => {
        clearAllMessages();

        if (!filesUploader) {
            return setErrorAndReject(t('Images Upload is not available.'), []);
        }

        const inputImagesCount = files.length;
        const currentUploadErrors: IUploadError[] = [];
        if (!inputImagesCount) {
            return setErrorAndReject(t('No images have been chosen.'), currentUploadErrors);
        }

        const uploadedImagesCount = uploadedImages.length;
        const availableUploadCount = maxCountImagesLimit - uploadedImagesCount;

        const formData = new FormData();
        formData.append('fileId', 'images');

        const {
            addedImagesCount,
            skippedImagesCount,
            filteredImages,
        } = filterInputImages(files, currentUploadErrors);

        for (let i = 0; i < Math.min(filteredImages.length, availableUploadCount); i += 1) {
            formData.append('images[]', filteredImages[i]);
        }

        if (!addedImagesCount) {
            return setErrorAndReject(
                t('No images to upload. All the chosen images have been skipped.'),
                currentUploadErrors,
            );
        }

        return new Promise(async (resolve, reject) => {
            await filesUploader.uploadImages(formData).then((uploadedImagesResult: IUploadImagesResponse[]) => {
                const successfullyUploaded = uploadedImagesResult.filter(image => image.error === 0);
                const uploadedImagesCount = successfullyUploaded.length;
                if (availableUploadCount <= uploadedImagesCount) {
                    const skippedOverLimitCount = inputImagesCount - uploadedImagesCount - skippedImagesCount;
                    setUploadInfoMessage(
                        t(
                            'Maximum upload images limit of %1 has been reached: %2 uploaded out of %3 chosen,' +
                            ' %4 skipped due to the maximum file size limit, %5 skipped due to the over limit.',
                            maxCountImagesLimit,
                            uploadedImagesCount,
                            inputImagesCount,
                            skippedImagesCount,
                            skippedOverLimitCount,
                        ),
                    );
                    showInfo(1000);
                } else if (inputImagesCount > uploadedImagesCount) {
                    const notUploadedImagesCount = addedImagesCount - uploadedImagesCount;
                    currentUploadErrors.push({
                        message: t(
                            'Not all the images have been uploaded: %1 uploaded out of %2 chosen,' +
                            ' %3 skipped due to maximum file size limit, %4 failed to upload to server.',
                            uploadedImagesCount,
                            inputImagesCount,
                            skippedImagesCount,
                            notUploadedImagesCount,
                        ),
                    });
                }

                setUploadErrors([
                    ...currentUploadErrors,
                ]);
                if (currentUploadErrors.length) {
                    showErrors(1000);
                }

                setUploadedImages([
                    ...uploadedImages,
                    ...successfullyUploaded,
                ]);
                updateChosenReturnItem({
                    updateUploadedImages: [
                        ...uploadedImages,
                        ...successfullyUploaded,
                    ],
                });
                return uploadedImagesCount ? resolve() : reject();
            }).catch(() => {
                const errorMessage = t(
                    'Something went wrong while uploading your %1 out of %2 images' +
                    ' (%3 skipped due to maximum file size limit).',
                    addedImagesCount,
                    inputImagesCount,
                    skippedImagesCount,
                );
                return setErrorAndReject(errorMessage, currentUploadErrors, reject);
            });
        });
    };

    const removeImageAction = (imageToRemove: IUploadImagesResponse) => {
        clearAllMessages(1000);
        const filteredUploadedImages = uploadedImages.filter(
            (image: IUploadImagesResponse) => image.url !== imageToRemove.url,
        );
        setUploadedImages([
            ...filteredUploadedImages,
        ]);
        updateChosenReturnItem({
            updateUploadedImages: [
                ...filteredUploadedImages,
            ],
        });
    };

    useEffect(() => {
        const chosenReturnItem: IChosenReturnItem|undefined = getReturnReasonsItemData(chosenReturnItems, rmaItemId);
        if (chosenReturnItem) {
            const {
                requestedQty: storedRequestedQty,
                remainingQty: storedRemainingQty,
                reason: storedReason,
                condition: storedCondition,
                comment: storedComment,
                uploadedImages: storedUploadedImages,
            } = chosenReturnItem;
            const { length } = storedComment;

            setRequestedQty(storedRequestedQty);
            setRemainingQty(storedRemainingQty);
            setReason(storedReason);
            setCondition(storedCondition);
            setComment(storedComment);
            setUploadedImages(storedUploadedImages);
            setRemainingCommentLength(commentMaxLength - length);
            setIsRmaItemChecked(true);
        } else {
            setRemainingQty(rmaAvailableQty);
        }

        getStepValidator(STEP_RETURN_REASONS, chosenReturnItems).validate();
    }, []);

    useEffect(() => {
        const reasonLabel = getLowerCaseLabel(rmaReasonOptions, reason);
        const isDamaged: boolean = reasonLabel.includes('damaged');
        setIsChosenDamaged(isDamaged);
    }, [reason]);

    useEffect(() => {
        if (!validateOnContinue) {
            return;
        }

        const chosenItem: IChosenReturnItem = getChosenReturnItem();

        setRequestedQtyStatus(isRequestedQtyValid(chosenItem) ? 'default' : 'invalid');
        setReasonStatus(isReasonValid(chosenItem) ? 'default' : 'invalid');
        setConditionStatus(isConditionValid(chosenItem) ? 'default' : 'invalid');
        setCommentStatus(isCommentValid(chosenItem) ? 'default' : 'invalid');
    }, [validateOnContinue]);

    useEffect(() => {
        if (rmaAvailableQty === 1) {
            onClickRequestedQty('1');
        }
    }, [rmaAvailableQty]);

    useEffect(() => {
        if (rmaConditionOptions.length < 1) {
            return;
        }

        const openedOption = getOptionByLabel(rmaConditionOptions, t('Opened'));
        if (openedOption) {
            const { value } = openedOption;
            onClickCondition(value);
        }
    }, [rmaConditionOptions]);

    return (
        <div className={styles.rmaItem}>
            <div
                className={classes(styles.rmaItemContent, styles.rmaItemDetails, {
                    [styles.checked]: isRmaItemChecked,
                })}
            >
                <div className={styles.rmaItemImageWrap}>
                    <div className={styles.rmaItemImageInnerWrap}>
                        <Checkbox
                            onChange={chooseRmaItem}
                            isChecked={isRmaItemChecked}
                        />
                        <ProductImage
                            productImageUrl={productImageUrl}
                            productName={productName}
                            className={styles.rmaProductImage}
                            counter={rmaAvailableQty}
                            counterClassName={styles.rmaAvailableQty}
                            width={140}
                            height={180}
                        />
                    </div>
                </div>
                <ProductDetails config={config} item={rmaItem} className={styles.rmaProductDetails}>
                    <>
                        {hasEmbroideredItems && (
                            <EmbroideryDetails
                                parentItem={rmaItem}
                                embroideryItems={embroideryItems}
                                label={t('Embroidery Details')}
                            />
                        )}
                    </>
                </ProductDetails>
            </div>
            {isRmaItemChecked && (
                <div className={classes(styles.rmaItemContent, styles.rmaItemOptions)}>
                    <div className={classes(styles.rmaItemOption, styles.rmaItemRequestedQty)}>
                        <div className={classes(styles.customSelect, styles.requestedQty)}>
                            {requestedQtyOptions.length > 0 && (
                                <Select
                                    options={requestedQtyOptions}
                                    selected={getSelectedOption(requestedQtyOptions, `${requestedQty}`)}
                                    placeholder={t('Choose quantity')}
                                    placeholderBeforeChosenValue={t('QTY')}
                                    onChange={onClickRequestedQty}
                                    status={requestedQtyStatus}
                                />
                            )}
                        </div>
                        <div className={styles.comment}>
                            <span>{t('Remaining quantity: %1', remainingQty)}</span>
                        </div>
                    </div>
                    <div className={classes(styles.rmaItemOption, styles.rmaItemReason)}>
                        <div className={styles.reasonDescription}>
                            <span className={styles.label}>{t('Why are you returning this?')}</span>
                        </div>
                        <div className={styles.reasonDescription}>
                            <span className={styles.text}>
                                {t('Selecting the most accurate reason for your return helps us gather essential' +
                                    ' feedback, that enables us to continuously improve our services and product' +
                                    ' quality.')}
                            </span>
                            {!hasEmbroideredItems && (
                                <Link
                                    label={t('Learn More')}
                                    href={productSupportUrl}
                                    linkClassName={styles.learnMoreLink}
                                    target="_blank"
                                    forceUnderline
                                    opacityOnHover
                                />
                            )}
                        </div>
                        {hasEmbroideredItems && (
                            <Policy policyConfig={policyConfig} />
                        )}
                    </div>
                    <div className={classes(styles.rmaItemOption, styles.rmaItemReason)}>
                        <div className={classes(styles.customSelect, styles.reason)}>
                            {rmaReasonOptions.length > 0 && (
                                <Select
                                    options={rmaReasonOptions}
                                    selected={getSelectedOption(rmaReasonOptions, reason)}
                                    placeholder={t('Choose a response')}
                                    onChange={onClickReason}
                                    status={reasonStatus}
                                />
                            )}
                        </div>
                    </div>
                    <div className={classes(styles.rmaItemOption, styles.rmaItemCondition)}>
                        <div className={classes(styles.customSelect, styles.condition)}>
                            {rmaConditionOptions.length > 0 && (
                                <Select
                                    options={rmaConditionOptions}
                                    selected={getSelectedOption(rmaConditionOptions, condition)}
                                    placeholder={t('Choose a condition')}
                                    onChange={onClickCondition}
                                    status={conditionStatus}
                                />
                            )}
                        </div>
                    </div>
                    <div className={classes(styles.rmaItemOption, styles.rmaItemComment)}>
                        <div className={styles.label}>
                            <span className={styles.main}>{t('Comments')}</span>
                            <span className={styles.secondary}>
                                {(rmaCommentRequired ? `(${t('required')})` : `(${t('optional')})`)}
                            </span>
                        </div>
                        <textarea
                            className={classes(styles.commentTextArea, {
                                [styles.invalid]: commentStatus === 'invalid',
                            })}
                            required={rmaCommentRequired}
                            maxLength={commentMaxLength}
                            onChange={onChangeComment}
                            value={comment}
                        />
                        <div className={styles.remainingCommentLength}>
                            <span className={styles.comment}>
                                {t('%1 characters remaining', remainingCommentLength)}
                            </span>
                        </div>
                    </div>
                    {!isChosenDamaged && (
                        <div className={classes(styles.rmaItemOption, styles.note)}>
                            <span className={styles.text}>
                                {t('NOTE: We aren\'t able to offer policy exceptions in response to comments.')}
                            </span>
                        </div>
                    )}
                    {isChosenDamaged && (
                        <div className={classes(styles.rmaItemOption, styles.fileUploaderWrap)}>
                            {!uploadedImages.length && (
                                <span className={styles.label}>
                                    {t('If product is defective, please upload image for a faster return process.')}
                                </span>
                            )}
                            <UploadedImages
                                uploadedImages={uploadedImages}
                                removeImageAction={removeImageAction}
                            />
                            <div className={styles.uploadErrorsWrapper}>
                                <div
                                    className={classes(styles.uploadErrors, {
                                        [styles.fadeIn]: showUploadErrors,
                                        [styles.fadeOut]: !showUploadErrors,
                                    })}
                                >
                                    {uploadErrors.map((error: IUploadError) => (
                                        <div className={styles.errorMessage}>{error.message}</div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.uploadInfoWrapper}>
                                <div
                                    className={classes(styles.uploadInfo, {
                                        [styles.fadeIn]: showUploadInfoMessage,
                                        [styles.fadeOut]: !showUploadInfoMessage,
                                    })}
                                >
                                    <div className={styles.infoMessage}>{uploadInfoMessage}</div>
                                </div>
                            </div>
                            {(showUploadErrors || showUploadInfoMessage) && (
                                <div className={styles.clearMessagesWrap}>
                                    <AccessibleButton
                                        tag="div"
                                        className={styles.clearMessagesBtn}
                                        action={() => clearAllMessages(1000)}
                                    >
                                        <div className={styles.infoMessage}>
                                            {(showUploadErrors && showUploadInfoMessage ? t('Clear All') : t('Clear'))}
                                        </div>
                                    </AccessibleButton>
                                </div>
                            )}
                            {uploadedImages.length < maxCountImagesLimit && (
                                <MultipleImageUploader
                                    handleFileChange={uploadImagesAction}
                                    acceptImages="image/png,image/jpg,image/jpeg"
                                    showUploadStatus
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
            {!isRmaItemChecked && (
                <div className={styles.rmaItemPolicyContent}>
                    <Policy policyConfig={policyConfig} />
                </div>
            )}
        </div>
    );
};
