import { ReactiveVar } from '@apollo/client';
import { Navigate } from '@silkpwa/module/router/use-navigate';
import { getUrl, getQueryParamByName } from 'ui/util/get-query-param-by-name';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import {
    IStepsValidation,
    createReturnsSteps,
    validateOnContinueVar,
    stepsVisibilityVar,
    stepsValidationVar,
    STEP_RETURN_REASONS,
    STEP_REFUND_OPTIONS,
    STEP_SHIPPING_OPTIONS,
    ACTION_CREATE_RETURNS,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';

const PARAM_STEP = 'step';

const updateValidationArray = (
    validationVar: ReactiveVar<IStepsValidation>,
    stepKey: string,
    isValid: boolean,
): void => {
    const validation: IStepsValidation = {
        ...validationVar(),
        [stepKey]: isValid,
    };
    validationVar(validation);
};

export const isStepValid = (
    stepKey: string,
): boolean => {
    const stepsValidation = stepsValidationVar();
    return stepsValidation[stepKey] || false;
};

export const updateStepValidation = (stepKey: string, isValid: boolean): void => {
    updateValidationArray(stepsValidationVar, stepKey, isValid);
};

export const updateStepVisibility = (stepKey: string, isVisible: boolean): void => {
    updateValidationArray(stepsVisibilityVar, stepKey, isVisible);
};

const isStepVisible = (stepKey: string): boolean => {
    const stepsVisibility = stepsVisibilityVar();
    return stepsVisibility[stepKey] || false;
};

export const updateStepsVisibility = (currentStepKey: string): void => {
    switch (currentStepKey) {
        case STEP_RETURN_REASONS:
            if (!isStepValid(STEP_RETURN_REASONS)) {
                if (isStepVisible(STEP_REFUND_OPTIONS)) {
                    updateStepVisibility(STEP_REFUND_OPTIONS, false);
                }

                if (isStepVisible(STEP_SHIPPING_OPTIONS)) {
                    updateStepVisibility(STEP_SHIPPING_OPTIONS, false);
                }
            }

            break;
        case STEP_REFUND_OPTIONS:
            if (!isStepValid(STEP_REFUND_OPTIONS)) {
                if (isStepVisible(STEP_SHIPPING_OPTIONS)) {
                    updateStepVisibility(STEP_SHIPPING_OPTIONS, false);
                }
            }
            break;
        default:
            break;
    }
};

export const isCurrentStepValid = (
    stepKey: string,
): boolean => (createReturnsSteps.includes(stepKey) && isStepValid(stepKey));

const resolveStep = (stepKey?: string|null, validate?: boolean): string => {
    if (!stepKey || !!(validate && !isCurrentStepValid(stepKey))) {
        return STEP_RETURN_REASONS;
    }

    return stepKey;
};

export const getNextStep = (currentStepKey: string): string => {
    switch (currentStepKey) {
        case STEP_RETURN_REASONS:
            return STEP_REFUND_OPTIONS;
        case STEP_REFUND_OPTIONS:
            return STEP_SHIPPING_OPTIONS;
        case STEP_SHIPPING_OPTIONS:
            return ACTION_CREATE_RETURNS;
        default:
            return STEP_RETURN_REASONS;
    }
};

export const getCurrentStepFromUrl = (validate?: boolean): string => {
    const tabParam: string|null = getQueryParamByName(PARAM_STEP);
    return resolveStep(tabParam, validate);
};

export const navigateToStep = (
    navigate: Navigate,
    stepKey?: string,
    isInitial?: boolean,
): void => {
    const url: URL = getUrl();
    const currentStep: string = resolveStep(stepKey);

    if (!isInitial) {
        const isStepValid = isCurrentStepValid(currentStep);
        validateOnContinueVar(!isStepValid);
    }

    url.searchParams.set(PARAM_STEP, currentStep);

    navigateToUrl({ navigate, url });
};


export const navigateToNextStep = (
    navigate: Navigate,
    currentStepKey: string,
): void => {
    if (!isCurrentStepValid(currentStepKey)) {
        validateOnContinueVar(true);
        return;
    }

    const url: URL = getUrl();
    const nextStepKey = getNextStep(currentStepKey);

    if (nextStepKey === ACTION_CREATE_RETURNS) {
        return;
    }

    updateStepVisibility(nextStepKey, true);
    url.searchParams.set(PARAM_STEP, nextStepKey);

    navigateToUrl({ navigate, url });
};
