import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ActionButton } from 'ui/component/custom/action-button';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import {
    IStepsValidation,
    stepsValidationVar,
    currentStepVar,
    validateOnContinueVar,
    allStepsValidVar,
    STEP_SHIPPING_OPTIONS,
    returnReasonsDataVar,
    refundOptionsDataVar,
    shippingOptionDataVar,
} from 'ui/page/sales-pages/rma-pages/create-returns-page/create-returns-state';
import {
    ISalesLayoutConfig,
    currentOrderVar,
} from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { navigateToNextStep } from '../../util/rma-steps-resolver';
import { RefundSummary } from './refund-summary';
import { ReturningItems } from './returning-items';
import { CreateReturnAction } from './create-return-action';
import styles from './style.css';

interface ICreateReturnsProps {
    config: ISalesLayoutConfig;
}

export const Sidebar: React.FC<ICreateReturnsProps> = ({ config }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const currentStepKey: string = useReactiveVar(currentStepVar);
    const stepsValidation: IStepsValidation = useReactiveVar(stepsValidationVar);
    const validateOnContinue: boolean = useReactiveVar(validateOnContinueVar);
    const allStepsValid: boolean = useReactiveVar(allStepsValidVar);

    const createReturnsOrder = useReactiveVar(currentOrderVar);
    const chosenReturnItems = useReactiveVar(returnReasonsDataVar);
    const chosenRefundOptions = useReactiveVar(refundOptionsDataVar);
    const chosenShippingOption = useReactiveVar(shippingOptionDataVar);

    const onClickContinue = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        navigateToNextStep(navigate, currentStepKey);
    };

    const isStepValid = stepsValidation[currentStepKey] || false;
    const allValidAndShippingStep = allStepsValid && currentStepKey === STEP_SHIPPING_OPTIONS;

    useEffect(() => {
        const isValid = !validateOnContinue || isStepValid;
        setIsDisabled(!isValid);
    }, [currentStepKey, validateOnContinue, isStepValid]);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(false);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }, 4500);
        }

        if (success) {
            setTimeout(() => {
                setSuccess(false);
            }, 4500);
        }
    }, [error, success]);

    return (
        <div
            className={classes(styles.stepContinue, {
                [styles.active]: isStepValid,
            })}
            data-test={`step-continue-${currentStepKey}`}
        >
            <div className={styles.sidebarContent}>
                {!allValidAndShippingStep && (
                    <ActionButton
                        label={t('Continue')}
                        btnClassName={styles.sidebarActionBtn}
                        disabled={isDisabled}
                        action={onClickContinue}
                    />
                )}
                {allValidAndShippingStep && (
                    <CreateReturnAction
                        config={config}
                        createReturnsOrder={createReturnsOrder}
                        chosenReturnItems={chosenReturnItems}
                        chosenRefundOptions={chosenRefundOptions}
                        chosenShippingOption={chosenShippingOption}
                        setError={setError}
                        setSuccess={setSuccess}
                    />
                )}
                <div className={styles.messageWrapper}>
                    <div
                        className={classes(styles.message, {
                            [styles.fadeIn]: error,
                            [styles.fadeOut]: !error,
                        })}
                    >
                        <div className={styles.errorMessage}>
                            <div>{t('We can\'t create a return right now. Please try again later.')}</div>
                            <div>{t('The page will be reloaded in 5 seconds.')}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.messageWrapper}>
                    <div
                        className={classes(styles.message, {
                            [styles.fadeIn]: success,
                            [styles.fadeOut]: !success,
                        })}
                    >
                        <div className={styles.successMessage}>
                            <div>{t('You have successfully created a return.')}</div>
                            <div>{t('You will be redirected to the RMAs page in 5 seconds.')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <RefundSummary config={config} />
            <ReturningItems config={config} />
        </div>
    );
};
